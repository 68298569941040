import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from './LanguageContext';  // Importa el hook de contexto


const propertyTexts = {
  es: {
    title: "Propiedades Residenciales",
    subtitle: "Descubre nuestras propiedades residenciales",
    learnMore: "Leer Más",
    noProperties: "No existen residencias actualmente."
  },
  en: {
    title: "Residential Properties",
    subtitle: "Discover our residential properties",
    learnMore: "Learn More",
    noProperties: "There are no residences currently."
  }
};

const Properties = () => {
  const { language } = useLanguage(); // Usa el contexto de idioma
  const [residentialProperties, setResidentialProperties] = useState([]);
  const texts = propertyTexts[language]; // Textos según el idioma actual

  useEffect(() => {
    fetch(`https://discovermexicohomes.com/api/residencias/residencias/${language}`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.residentialProperties && Array.isArray(data.residentialProperties)) {
          setResidentialProperties(data.residentialProperties);
        } else {
          console.error('Unexpected data structure:', data);
        }
      })
      .catch(error => console.error('Fetch error:', error));
  }, [language]);

  return (
    <>
      <section id="residencial" className="container px-6 py-16 mx-auto">
        <div className="mb-12 text-center">
          <h2 className="text-3xl font-bold md:text-4xl">{texts.title}</h2>
          <p className="mt-4 text-gray-700">{texts.subtitle}</p>
        </div>
        <div className="flex flex-col">
          {residentialProperties.length > 0 ? (
            residentialProperties.map((property, index) => (
              <div key={index} className="flex w-full p-4 my-2 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                <div className='flex md:w-3/4'>
                  <img src={property.mainImage || 'https://via.placeholder.com/400'} alt={`Residencial ${index + 1}`} className="object-cover w-full rounded h-96" />
                </div>
                <div className='flex flex-col p-6 md:w-1/4'>
                  <h3 className="mt-4 text-2xl font-bold text-start">{property.title}</h3>
                  <p className="mt-2 text-gray-600 text-start">{property.description}</p>
                  <button className="w-full mt-4 button">
                    <Link className="w-full button-content" to={`/property/${property.id}`}>{texts.learnMore}</Link>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <h3 className="text-2xl text-center text-gray-600 ">{texts.noProperties}</h3>
          )}
        </div>
      </section>
    </>
  );
};

export default Properties;
