import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Logo from '../img/Lizard.png';
import { Link } from 'react-router-dom';
import { useLanguage } from './LanguageContext';  // Importa el hook de contexto

const navTexts = {
  es: {
    home: "Inicio",
    aboutUs: "Nosotros",
    properties: "Propiedades",
    partners: "Partners",
    news: "Noticias",
    contact: "Contacto", 
    discoverTitle: "Discover Mexico Homes",
    discoverSubtitle: "Discover - Invest - Live",
    spanish: 'Español',
    english: 'Inglés'
  },
  en: {
    home: "Home",
    aboutUs: "About Us",
    properties: "Properties",
    partners: "Partners",
    news: "News",
    contact: "Contact",
    discoverTitle: "Discover Mexico Homes",
    discoverSubtitle: "Discover - Invest - Live",
    spanish: 'Spanish',
    english: 'English'
  }
};

const Navbar = ({ showLanguageButton = true }) => {
  const { language, toggleLanguage } = useLanguage();  // Usa el contexto
  const [isOpen, setIsOpen] = useState(false);
  const [showPropertiesDropdown, setShowPropertiesDropdown] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [properties, setProperties] = useState([]);
  
  const texts = navTexts[language];   // Accede a los textos según el idioma actual

  // Actualiza useEffect para incluir `language` en las dependencias
  useEffect(() => {
    fetch(`https://discovermexicohomes.com/api/residencias/residencias/${language}`)
      .then(response => response.json())
      .then(data => {
        if (data.residentialProperties && Array.isArray(data.residentialProperties)) {
          setProperties(data.residentialProperties);
        } else {
          console.error('Unexpected data structure:', data);
        }
      })
      .catch(error => console.error('Fetch error:', error));
  }, [language]); // Agrega `language` a las dependencias para recargar los datos cuando cambie


  const togglePropertiesDropdown = () => {
    setShowPropertiesDropdown(!showPropertiesDropdown);
  };

  const closeDropdowns = () => {
    setShowPropertiesDropdown(false);
    setShowLanguageDropdown(false);
  };

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };

  const handleLanguageChange = (lang) => {
    setIsOpen(false); // Cierra el menú desplegable al cambiar el idioma
    toggleLanguage(lang); // Usa la función del contexto
    setShowLanguageDropdown(false); // Cierra el dropdown de idiomas después de seleccionar uno
  };

  return (
    <nav className="absolute z-30 w-full bg-white shadow md:fixed md:mb-42">
      <div className="container flex items-center justify-between h-full p-6 mx-auto">
        <div className="flex items-center space-x-2">
          <a href="/" className='w-16 h-12 md:h-20 md:w-28'>
            <img id="logo" className="w-full h-full" src={Logo} alt="Logo" />
          </a>
          <div className='flex flex-col'>
            <a href="/" className="text-xl font-bold text-center text-blue-700 md:text-2xl">{texts.discoverTitle}</a>
            <a href="/" className="flex justify-end text-sm font-semibold text-center text-blue-500">{texts.discoverSubtitle}</a>
          </div>
        </div>
        <div className="hidden space-x-4 md:flex">
          <Link to={'/'} className="hover:text-blue-600">{texts.home}</Link>
          <Link to={'/about-us'} className="hover:text-blue-600">{texts.aboutUs}</Link>
          <div className="relative">
            <Link onClick={togglePropertiesDropdown} className="relative hover:text-blue-600">{texts.properties}</Link>
            {showPropertiesDropdown && (
              <div onBlur={closeDropdowns} className="absolute left-0 w-48 py-2 bg-white rounded shadow-lg">
                {properties.map(property => (
                  <Link key={property.id} to={`/property/${property.id}`} className="block px-4 py-2 hover:bg-gray-100">{property.title}</Link>
                ))}
              </div>
            )}
          </div>
          <Link to="/partners" className="hover:text-blue-600">{texts.partners}</Link>
          <Link to="/noticias" className="hover:text-blue-600">{texts.news}</Link>
          <Link to="/contacto" className="hover:text-blue-600">{texts.contact}</Link>
          {showLanguageButton && (
            <div className="relative">
              <button onClick={toggleLanguageDropdown} className="hover:text-blue-600">
                <FontAwesomeIcon icon={faGlobe} />
              </button>
              {showLanguageDropdown && (
                <div onBlur={closeDropdowns} className="absolute right-0 w-32 py-2 mt-2 bg-white rounded shadow-lg">
                  <button onClick={() => handleLanguageChange('es')} className="block w-full px-4 py-2 text-left hover:bg-gray-100">Español</button>
                  <button onClick={() => handleLanguageChange('en')} className="block w-full px-4 py-2 text-left hover:bg-gray-100">English</button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-blue-600 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="relative pb-8 md:hidden">
          <Link to="/" className="block px-4 py-2">{texts.home}</Link>
          <Link to="/about-us" className="block px-4 py-2">{texts.aboutUs}</Link>
          <div className="relative px-4 py-2">
            <button onClick={togglePropertiesDropdown} className="hover:text-blue-600">{texts.properties}</button>
            {showPropertiesDropdown && (
              <div onBlur={closeDropdowns} className="absolute w-48 py-2 mt-2 bg-white rounded shadow-lg">
                {properties.map(property => (
                  <Link key={property.id} to={`/property/${property.id}`} className="block px-4 py-2 hover:bg-gray-100">{property.title}</Link>
                ))}
              </div>
            )}
          </div>
          <Link to="/partners" className="block px-4 py-2">{texts.partners}</Link>
          <Link to="/noticias" className="block px-4 py-2">{texts.news}</Link>
          <Link to="/contacto" className="block px-4 py-2">{texts.contact}</Link>
          {showLanguageButton && (
            <div className="relative px-4 py-2 mb-6">
              <button onClick={toggleLanguageDropdown} className="hover:text-blue-600">
                <FontAwesomeIcon icon={faGlobe} />
              </button>
              {showLanguageDropdown && (
                <div onBlur={closeDropdowns} className="absolute w-48 py-2 mt-2 bg-white rounded shadow-lg">
                  <button onClick={() => toggleLanguage('es')} className="block w-full px-4 py-2 text-left hover:bg-gray-100">{texts.spanish}</button>
                  <button onClick={() => toggleLanguage('en')} className="block w-full px-4 py-2 text-left hover:bg-gray-100">{texts.english}</button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
