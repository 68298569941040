// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from '../src/crud/AuthContext'; 
import Inicio from './Inicio';
import Contacto from './components/Contact';
import NewsPage from './components/NewsPage';
import Property from './components/Property';
import AboutUs from './components/AboutUs';
import ProtectedRoute from './crud/ProtectedRoute';
import CrudProperties from './crud/Residencies';
import CrudHouses from './crud/Houses';
import CrudNews from './crud/News';
import CrudLogin from './crud/Login';
import Partners from './components/Partners';
import { LanguageProvider } from './components/LanguageContext';

function App() {
  return (
    <AuthProvider>  {/* Asegúrate de que AuthProvider envuelve todo el Router */}
      <Router>
        <Routes>
          <Route path="/" element={<LanguageProvider><Inicio /></LanguageProvider>} />
          <Route path="/property/:id" element={<LanguageProvider><Property /></LanguageProvider>} />
          <Route path="/about-us" element={<LanguageProvider><AboutUs /></LanguageProvider>} />
          <Route path="/contacto" element={<LanguageProvider><Contacto /></LanguageProvider>} />
          <Route path="/noticias" element={<LanguageProvider><NewsPage /></LanguageProvider>} />
          <Route path="/partners" element={<LanguageProvider><Partners /></LanguageProvider>} />
          <Route path="/administration/login" element={<CrudLogin />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/administration/residencies" element={<CrudProperties />} />
            <Route path="/administration/houses" element={<CrudHouses />} />
            <Route path="/administration/news" element={<CrudNews />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
