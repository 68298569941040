// LanguageContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const [language, setLanguage] = useState('es');

  useEffect(() => {
    const lang = new URLSearchParams(location.search).get('lang');
    if (lang) {
      setLanguage(lang);
    }
  }, [location]);

  const toggleLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
