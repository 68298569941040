  import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import Navbar from './Navbar';
import Footer from './Footer';
import PropertyImageGallery from './PropertyImagesGallery';
import { useLanguage } from './LanguageContext';  // Importa el hook de contexto


const propertyTexts = {
  es: {
    subtitle: "Propiedades Disponibles",
    subtitle2: "No hay casas disponibles"
  },

  en: {
    subtitle: "Available Properties",
    subtitle2: "No houses available"
  }
}

const Property = () => {
  const { language } = useLanguage(); // Usa el contexto de idioma
  const texts = propertyTexts[language]
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [houses, setHouses] = useState([]);

  const showLanguageButton = language === 'en' && language === 'es' ? false : false;

  // Fetch the property details
  useEffect(() => {
    fetch(`https://discovermexicohomes.com/api/residencias/${id}`)
      .then(response => response.json())
      .then(data => {
        setProperty(data.residentialProperty);
      })
      .catch(error => console.error('Fetch error:', error));
  }, [id]); // Depend on `id` to refetch when it changes

  // Fetch houses and apply filters
  useEffect(() => {
    if (!property) {
      console.log('Property data is not yet available');
      return; // Exit if property data is not available
    }

    fetch(`https://discovermexicohomes.com/api/casas/casas/${language}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        return response.json();
      })
      .then(data => {
        if (data.houses && Array.isArray(data.houses)) {
          console.log("Raw house data:", data.houses);  
          const currentId = parseInt(id, 10); 
          const residenceIsEnglish = parseInt(property.isEnglish);
          console.log("Residence isEnglish value:", residenceIsEnglish);

          const filteredHouses = data.houses.filter(house =>
            parseInt(house.id_residence, 10) === currentId && 
            parseInt(house.isEnglish, 10) === residenceIsEnglish
          );
          setHouses(filteredHouses);
        } else {
          console.error('Unexpected data structure:', data);
        }
      })
      .catch(error => console.error('Error fetching houses:', error));
  }, [id, property, language]);
  
  useEffect(() => {
    console.log("Updated houses state:", houses);
  }, [houses]);
  

  if (!property) {
    return <div>Propiedad no encontrada</div>;
  }

  return (
    <>
      <Navbar showLanguageButton={showLanguageButton} />
      <section className="container w-full px-6 py-6 mx-auto md:px-6">
        <h2 className="mt-48 text-2xl font-semibold">{property.title}</h2>
        <p className="mt-6 mb-6 text-lg">{property.longDescription}</p>
        
        <PropertyImageGallery propertyImages={property.images} />
        <h2 className='flex mt-10 text-2xl font-semibold text-center'>{texts.subtitle}</h2>
        <div className="flex flex-wrap justify-center">
          {houses.length > 0 ? houses.map((house, index) => (
            <div key={index} className="max-w-sm m-6 mb-6 overflow-hidden bg-white rounded shadow-lg hover:shadow-xl">
              <img 
                className="w-full object-cover h-64" 
                src={house.images[0] ? house.images[0] : 'https://via.placeholder.com/400'} 
                alt="Property Image" 
              />
              <div className="px-6 py-4">
                <div className="mb-2">
                  <h2 className="text-xl font-bold text-center text-gray-900 md:text-start">{property.title}</h2>
                  <div className="flex items-center">
                    <div className="px-2 py-1 my-2 mr-2 text-xs font-medium text-white bg-blue-600 rounded-full">
                      {house.name}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img src="https://img.icons8.com/windows/24/null/bedroom.png" alt="Bedrooms Icon" />
                    <p className="ml-2 text-sm font-medium text-gray-700">{house.rooms} Bedrooms</p>
                  </div>
                  <div className="flex items-center">
                    <img src="https://img.icons8.com/pastel-glyph/24/null/bath--v2.png" alt="Bathrooms Icon" />
                    <p className="ml-2 text-sm font-medium text-gray-700">{house.bathrooms} Bathrooms</p>
                  </div>
                  <div className="flex items-center">
                    <img src="https://img.icons8.com/ios-glyphs/24/null/expand--v1.png" alt="Size Icon" />
                    <p className="ml-2 text-sm font-medium text-gray-700">{house.land}</p>
                  </div>
                </div>
                <div className="w-full mt-4">
                  <p className="text-2xl font-bold text-center text-blue-800 md:text-start">{house.price}</p>
                </div>
              </div>
              <div className="flex items-center justify-center px-6 py-4">
                <div className="flex">
                  <a href="tel:+1234567890" className="flex justify-center object-cover w-full p-3 m-auto mb-2 mr-2 text-gray-700 transition duration-300 ease-in-out bg-gray-300 rounded-full md:justify-end hover:bg-gray-400 hover:text-gray-800">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </a>
                  <a href="https://wa.me/1234567890" className="flex object-cover w-full p-3 m-auto mb-2 text-white transition duration-300 ease-in-out bg-green-500 rounded-full hover:bg-green-600">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </div>
              </div>
            </div>
          )) : <h3 className="text-2xl text-center text-gray-600">{texts.subtitle2}</h3>}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Property;
