import React from 'react';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom';
import Picture1 from '../img/temozon.webp'
import Picture2 from '../img/temozon2.webp'
import Picture3 from '../img/zaguan.webp'
import Picture4 from '../img/zaguan2.jpg'

const aboutTexts = {
  es: {
    whyUs: "¿Por qué nosotros?",
    guidingTitle: "Te guíamos a encontrar la casa perfecta",
    description1: "Si has decidido mudarte a Mérida, es posible que te sientas indeciso, abrumado, confundido o preocupado.",
    description2: "Ya no hay necesidad de sentirte así. Estamos aquí para ayudarte a navegar el proceso de elegir y comprar:",
    description3: "• Una casa de vacaciones",
    description4: "• Una propiedad de inversión (a largo o corto plazo)",
    description5: "• Una propiedad para el retiro",
    description6: "Lo más importante es que nos esforzamos para que tengas una experiencia maravillosa y disfrutes del proceso de principio a fin.",
    learnMore: "Conocer Más"
  },
  en: {
    whyUs: "Why Choose Us?",
    guidingTitle: "We Guide You to Find the Perfect Home",
    description1: "If you have decided to move to Merida, you might be feeling doubtful, overwhelmed, confused, or concerned.",
    description2: "There’s no need to feel this way any longer. We’re here to help you navigate the process of choosing and purchasing:",
    description3: "• A vacation home",
    description4: "• An investment property (long or short term)",
    description5: "• A retirement property",
    description6: "More importantly, we strive to ensure that you have a wonderful experience and enjoy the process from start to finish.",
    learnMore: "Learn More"
  }
};

const About = () => {

  const { language } = useLanguage();
  const texts = aboutTexts[language];


  return (
    <>
      <section className="overflow-hidden md:pt-32 pb-12 lg:pt-[120px] lg:pb-[90px] px-6 pt-72 md:mt-0" style={{backgroundColor: '#EAECEF'}}>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center justify-between -mx-2">
            {/* Sección de Imágenes en Collage */}
            <div className="relative w-full px-4 lg:w-1/2 h-[300px] sm:h-[400px] md:h-[500px]">
              <div className="absolute inset-0 grid grid-cols-2 gap-4 px-6">
                <div className="relative group">
                  <img
                    src={Picture1}
                    alt="Residencial Temozón Norte en Mérida"
                    className="object-cover w-full h-full transition duration-500 ease-in-out transform shadow-lg rounded-2xl group-hover:scale-105"
                  />
                </div>
                <div className="relative group">
                  <img
                    src={Picture2}
                    alt="Residencial Temozón Norte en Mérida"
                    className="object-cover w-full h-full transition duration-500 ease-in-out transform shadow-lg rounded-2xl group-hover:scale-105"
                  />
                </div>
                <div className="relative group">
                  <img
                    src={Picture3}
                    alt="Residencial Zaguan en Mérida"
                    className="object-cover w-full h-full transition duration-500 ease-in-out transform shadow-lg rounded-2xl group-hover:scale-105"
                  />
                </div>
                <div className="relative group">
                  <img
                    src={Picture4}
                    alt="Residencial Zaguan en Mérida"
                    className="object-cover w-full h-full transition duration-500 ease-in-out transform shadow-lg rounded-2xl group-hover:scale-105"
                  />
                </div>
              </div>
            </div>

            {/* Sección de Texto */}
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-lg font-semibold text-blue-600 text-primary">
                  {texts.whyUs}
                </span>
                <h2 className="mb-5 text-3xl font-bold text-dark sm:text-[40px]/[48px]">
                  {texts.guidingTitle}
                </h2>
                <p className="mb-5 text-base text-body-color">
                  {texts.description1}
                </p>
                <p className="mb-8 text-base text-body-color">
                  {texts.description2}
                </p>
                <p className="mb-8 text-base text-body-color">
                  {texts.description3}
                </p>
                <p className="mb-8 text-base text-body-color">
                  {texts.description4}
                </p>
                <p className="mb-8 text-base text-body-color">
                  {texts.description5}
                </p>
                <p className="mb-8 text-base text-body-color">
                  {texts.description6}
                </p>
                <Link
                  to="/about-us"
                  className="inline-flex items-center justify-center py-3 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md px-7 hover:bg-opacity-90"
                >
                  {texts.learnMore}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default About;
