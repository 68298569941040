import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import AboutGallery from './components/AboutGallery';
import Properties from './components/Properties';
import News from './components/News';
import Spinner from './components/Spinner'; 
import Footer from './components/Footer';

// import App from './App';
import './style.css';
import 'lightbox2/dist/css/lightbox.min.css'; 
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';

const Inicio = () => {
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false); // Cuando la página se haya cargado, oculta el spinner
    }, 5000); // Simula una carga de 2 segundos, ajusta según sea necesario

    return () => clearTimeout(timeout);
  }, []);

  return (
      <div>
        {loading ? ( 
          <Spinner />
        ) : ( 
          <>
            <Navbar />
            <Hero />
            <About />
            <AboutGallery/>
            <Properties />
            <News />
            <Footer />
          </>
        )}
      </div>
  );
};

export default Inicio;
