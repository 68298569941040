import React, { useEffect, useState } from 'react';
import NavAside from './NavAside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

const Properties = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [residentialProperties, setResidentialProperties] = useState([]);
  const [houses, setHouses] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    id_residence: '',
    rooms: '',
    bathrooms: '',
    land: '',
    price: '',
    isEnglish: false,
    images: []
  });

  useEffect(() => {
    fetch('https://discovermexicohomes.com/api/casas/casas/all')
      .then(response => response.json())
      .then(data => setHouses(data));
  }, []);

  useEffect(() => {
    fetch('https://discovermexicohomes.com/api/residencias/residencias/all')
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setResidentialProperties(data)});
      console.log(residentialProperties)
  }, []);

const openEditModal = (item) => {
    setSelectedItem(item);
    console.log(Boolean(item.isEnglish))
    setFormData({
      name: item.name,
      id_residence: item.id_residence.toString(),
      rooms: item.rooms || '',
      bathrooms: item.bathrooms || '',
      land: item.land || '',
      price: item.price || '',
      isEnglish: Boolean(item.isEnglish),
      images: item.images || []
    });
  };  

  const closeEditModal = () => {
    setSelectedItem(null);
    setFormData({
        name: '',
        id_residence: '',
        rooms: '',
        bathrooms: '',
        land: '',
        price: '',
        isEnglish: '',
        images: []
    });
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
    setFormData({
        name: '',
        id_residence: '',
        rooms: '',
        bathrooms: '',
        land: '',
        price: '',
        isEnglish: '',
        images: []
    });
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setFormData({
        name: '',
        id_residence: '',
        rooms: '',
        bathrooms: '',
        land: '',
        price: '',
        isEnglish: '',
        images: []
    });
  };

  const handleDelete = async (id) => {
    const confirmar = window.confirm("¿Deseas eliminar esta casa?");
    if (confirmar) {
      try {
        const url = `https://discovermexicohomes.com/api/casas/eliminar/${id}`;
        const respuesta = await fetch(url, {
          method: "DELETE",
        });
        await respuesta.json();
        setHouses(houses.houses.filter((house) => house.id !== id));
      } catch (error) {
        console.error(error);
      }
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const processedMainImage = formData.mainImage && formData.mainImage instanceof Blob ? await convertToBase64(formData.mainImage) : formData.mainImage;
      const processedImages = formData.images.length > 0 && formData.images[0] instanceof Blob
      ? await Promise.all(formData.images.map(image => image instanceof Blob ? convertToBase64(image) : image))
      : formData.images;
      // console.log(formData)
      // console.log(processedMainImage)
      // console.log(processedImages)
      
      const dataToSubmit = {
        ...formData,
        isEnglish: formData.isEnglish ? 1 : 0,
        // mainImage: processedMainImage,
        images: processedImages
      };

      console.log(dataToSubmit)

      const url = selectedItem
        ? `https://discovermexicohomes.com/api/casas/editar/${selectedItem.id}`
        : 'https://discovermexicohomes.com/api/casas/nuevo';
      const method = selectedItem ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        body: JSON.stringify(dataToSubmit),
        headers: {"Content-Type": "application/json"},
      });
      const updatedHouse = await response.json();
      
      setHouses(prevState => {
        if (!Array.isArray(prevState)) {
          console.error("Invalid state encountered:", prevState);
          return [];
        }
      
        return selectedItem
          ? prevState.map(house => house.id === selectedItem.id ? { ...house, ...updatedHouse } : house)
          : [...prevState, updatedHouse];
      });
      window.alert("Registro guardado con éxito")
      closeEditModal();
      closeAddModal();
      fetchHouses(); // Optionally refresh list after update
    } catch (error) {
      console.error('Failed to submit property:', error);
      window.alert("Error al guardar el registro")
    }
  };

  const fetchHouses = async () => {
    try {
      const response = await fetch('https://discovermexicohomes.com/api/casas/casas/all');
      const data = await response.json();
      setHouses(data);
    } catch (error) {
      console.error('Failed to fetch properties:', error);
    }
  }
  
  useEffect(() => {
    fetchHouses();
  }, []);  
  
  
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];  // Asegura que solo la parte de Base64 se utilice
        resolve(base64Data);
      };
      reader.onerror = error => reject(error);
    });
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };  

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "images") {
      setFormData({
        ...formData,
        images: files ? Array.from(files) : []
      });
    }
  };

  const removeImage = (indexToRemove) => {
    setFormData({
      ...formData,
      images: formData.images.filter((_, index) => index !== indexToRemove)
    });
  };

  const renderImages = (images) => {
    if (!images) return null;
    return images.map((image, index) => {
      const imageUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
      return (
        <div key={index} className="relative mb-2 mr-2">
          <img
            src={imageUrl}
            alt={`Preview ${index}`}
            className="object-cover rounded-md w-28 h-28"
          />
        </div>
      );
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };  
  
  return (
    <>
      <NavAside isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="flex">
        <main className={`p-8 mt-14 transition-all duration-300 ${isOpen ? 'ml-64' : 'ml-0'}`}>
          <h1 className="mb-4 text-2xl font-bold">Propiedades</h1>
          <button onClick={openAddModal} className="px-4 py-2 mb-4 text-white bg-blue-600 rounded-md">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Agregar Propiedad
          </button>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {houses && houses.houses ? (
              houses.houses.map((item) => (
                <div key={item.id} className="overflow-hidden bg-white rounded-lg shadow-md">
                  <img src={item.images[0]} alt={item.name} className="object-cover w-full h-48" />
                  <div className="p-4">
                    <h2 className="mb-2 text-xl font-bold">{item.name}</h2>
                    <h4 className='inline-flex w-full mb-2 font-semibold'>Habitaciones: <p className="pl-2 font-normal text-gray-600"> {item.rooms}</p></h4>
                    <h4 className='inline-flex w-full mb-2 font-semibold'>Baños: <p className="pl-2 font-normal text-gray-600"> {item.bathrooms}</p></h4>
                    <h4 className='inline-flex w-full mb-2 font-semibold'>Terreno: <p className="pl-2 font-normal text-gray-600"> {item.land}</p></h4>
                    <h4 className='inline-flex w-full mb-4 font-semibold'>Precio: <p className="pl-2 font-normal text-gray-600"> {item.price}</p></h4>
                    <div className="flex space-x-1">
                      <button onClick={() => openEditModal(item)} className="px-4 py-2 my-2 text-white bg-yellow-500 rounded-md">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => handleDelete(item.id)} className="px-4 py-2 my-2 text-white bg-red-500 rounded-md">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h4>No existen propiedades actualmente.</h4>
            )}
          </div>
        </main>

      {selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-screen-md w-full p-8 overflow-y-auto max-h-[96vh]">
            <h2 className="mb-4 text-2xl font-bold">Editar Propiedad</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                  * Nombre
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Nombre"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label for="id_residence" className="block mb-2 text-sm font-bold text-gray-700">* Residencia</label>
                <select
                    id="id_residence"
                    name="id_residence"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.id_residence}
                    onChange={handleChange} 
                    required
                    >
                    <option selected value="">Selecciona una residencia</option>
                    {residentialProperties.residentialProperties.map((item) => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="rooms">
                  * Habitaciones
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="rooms"
                  placeholder="Habitaciones"
                  name="rooms"
                  value={formData.rooms}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="bathrooms">
                  * Baños
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="bathrooms"
                  placeholder="Baños"
                  name="bathrooms"
                  value={formData.bathrooms}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="land">
                  * Tamaño Terreno
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="land"
                  placeholder="Tamaño Terreno"
                  name="land"
                  value={formData.land}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="price">
                  * Precio
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="price"
                  placeholder="Precio"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-8">
                <label class="inline-flex items-center cursor-pointer">
                  <span class="text-sm font-bold text-gray-700 mr-8">Contenido en Inglés</span>
                  <input 
                    type="checkbox"
                    className="sr-only peer" 
                    name="isEnglish"
                    checked={formData.isEnglish}
                    onChange={handleCheckboxChange}
                  />
                  <div class="flex relative items-end w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="images">
                  * Otras Imágenes
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="images"
                  type="file"
                  accept="image/*"
                  multiple
                  name="images"
                  onChange={handleFileChange}
                />
                <div className="flex flex-wrap mt-2">
                  {renderImages(formData.images)}
                </div>
              </div>
              <div className="flex justify-end">
                <button onClick={closeEditModal} className="px-4 py-2 mr-2 text-gray-700 bg-gray-300 rounded-md">
                  Cancelar
                </button>
                <button type="submit" className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isAddModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-screen-md p-8 overflow-y-auto bg-white rounded-lg max-h-[96vh]">
            <h2 className="mb-4 text-2xl font-bold">Agregar Propiedad</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                  * Nombre
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Nombre"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label for="id_residence" className="block mb-2 text-sm font-bold text-gray-700">* Residencia</label>
                <select
                    id="id_residence"
                    name="id_residence"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.id_residence}
                    onChange={handleChange} 
                    required
                    >
                    <option selected value="">Selecciona una residencia</option>
                    {residentialProperties.residentialProperties.map((item) => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="rooms">
                  * Habitaciones
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="rooms"
                  placeholder="Habitaciones"
                  name="rooms"
                  value={formData.rooms}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="bathrooms">
                  * Baños
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="bathrooms"
                  placeholder="Baños"
                  name="bathrooms"
                  value={formData.bathrooms}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="land">
                  * Tamaño Terreno
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="land"
                  placeholder="Tamaño Terreno"
                  name="land"
                  value={formData.land}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="price">
                  * Precio
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="price"
                  placeholder="Precio"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-8">
                <label class="inline-flex items-center cursor-pointer">
                  <span class="text-sm font-bold text-gray-700 mr-8">Contenido en Inglés</span>
                  <input 
                    type="checkbox"
                    className="sr-only peer" 
                    name="isEnglish"
                    checked={formData.isEnglish}
                    onChange={handleCheckboxChange}
                  />
                  <div class="flex relative items-end w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="images">
                  * Otras Imágenes
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="images"
                  type="file"
                  accept="image/*"
                  multiple
                  name="images"
                  onChange={handleFileChange}
                  required
                />
                <div className="flex flex-wrap mt-2">
                  {renderImages(formData.images)}
                </div>
              </div>
              <div className="flex justify-end">
                <button onClick={closeAddModal} className="px-4 py-2 mr-2 text-gray-700 bg-gray-300 rounded-md">
                  Cancelar
                </button>
                <button type="submit" className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  </>
  );
};

export default Properties;
