import React from 'react';
import { useLanguage } from './LanguageContext';  // Importa el hook de contexto
import 'tailwindcss/tailwind.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faHandshake, faCalendarCheck, faMapMarkedAlt, faMoneyCheckAlt, faTruckMoving, faCreditCard, faScaleBalanced, faCar, faFileSignature, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import Fondo from '../img/aboutUs.jpg'
import Member1 from '../img/teamMembers/xiomaraBussio.jpg'
import Member2 from '../img/teamMembers/teeBee.png'
import Member3 from '../img/teamMembers/mariaRosario.png'
import Member4 from '../img/teamMembers/nancyPuig.png'
import Member5 from '../img/teamMembers/jorgeMoscosso.png'
import Member6 from '../img/teamMembers/gamarMilano.png'
import Member7 from '../img/teamMembers/mikeLerner.png'
import Member8 from '../img/teamMembers/av.jpg'
import Member9 from '../img/teamMembers/joseEduardo.png'
import Member10 from '../img/teamMembers/agobao.png'

import Navbar from './Navbar';
import Footer from './Footer';

const aboutTexts = {

  es : {
    subtitle1: "Lo que nos impulsa",
    desc1: "Bienvenido a DMH Real Estate, nuestro propósito es de inspirar, transformar vidas a través del cuidado y la mejora constante. Nos comprometemos a satisfacer las necesidades de nuestros clientes, motivar a nuestro equipo y optimizar la calidad de los servicios.",
    subtitle2: "El cuidado comienza con la cultura",
    desc2: "Nuestra firme creencia en la mejora continua nos guía todos los días. Como un equipo unido, impulsado por nuestro propósito, misión y valores, trabajamos juntos para brindar un cuidado y servicio excepcionales. Esta dedicación no son solo palabras: es la esencia de quienes somos.",
    subtitle3: "Liderazgo",
    desc3: "Nuestro equipo de ejecutivo está compuesto por líderes de la industria con experiencia en construir un futuro mejor para nuestros clientes, y comunidade a las que le prestamos servicio.",
    subtitle4: "Empacar sus vidas y comenzar de nuevo en un país nuevo es un gran cambio para muchos.",
    desc4: "Nos enorgullece presentarnos. Mi nombre es Xio (Zio) y mi esposo es Tee Bee. Hemos sido agentes inmobiliarios multilingües desde 2008, y actualmente vivimos en Mérida, Yucatán. Nos mudamos de Houston, Texas, en septiembre de 2023, y estamos aquí para asistirle de manera eficiente, sin estrés y a un costo accesible.",
    desc5: "Prestamos servicio tanto en Houston, Texas, San Juan Puerto Rico, como Mérida, Yucatán, México. Colaboramos con propietarios locales, empresas, constructores y desarrolladores que comparten nuestra filosofía: crear valor desde el princio hasta la finalización de proyectos inmobiliarios de alta calidad y altamente rentables para nuestros clientes e inversionistas por igual.",
    desc6: "Si planea vivir o invertir en bienes raíces en la ciudad de Mérida o en el estado de Yucatán, ha llegado al lugar indicado. Ofrecemos una gama cuidadosamente seleccionada de casas, apartamentos, oficinas, penthouses y terrenos, todos elegidos para proporcionar absoluta seguridad legal y los mejores rendimientos de su inversión. Lo más importante, nos esforzamos por asegurar que tenga una experiencia maravillosa y disfrute del proceso de principio a fin.",

    para1: `Nos enorgullece presentarnos. Mi nombre es Zio y mi esposo es Tee Bee. Hemos sido agentes inmobiliarios multilingües desde 2008, y actualmente vivimos en Mérida, Yucatán. Nos trasladamos de Houston, Texas, a Mérida en septiembre de 2023, y estamos aquí para asistirle de manera eficiente, sin estrés y a un costo accesible.`,
    para2: `Prestamos servicio tanto en Houston, Texas, como Mérida, Yucatán, México. Colaboramos con propietarios locales, empresas, constructores y desarrolladores que comparten nuestra filosofía: crear valor desde el princio hasta la finalización de proyectos inmobiliarios de alta calidad y altamente rentables para nuestros clientes e inversionistas por igual.`,
    para3: `Si planea vivir o invertir en bienes raíces en la ciudad de Mérida o en el estado de Yucatán, ha llegado al lugar indicado. Ofrecemos una gama cuidadosamente seleccionada de casas, apartamentos, oficinas, penthouses y terrenos, todos elegidos para proporcionar absoluta seguridad legal y los mejores rendimientos de su inversión. Lo más importante, nos esforzamos por asegurar que tenga una experiencia maravillosa y disfrute del proceso de principio a fin.`,
    subtitle5: "Esto es lo que puede esperar de nosotros:",
    airport: "Recogida y entrega en el aeropuerto",
    program: "Programación y visita a propiedades",
    progreso: "Conducirle por la ciudad y a la playa en Progreso",
    price: "Negociar el mejor precio que se ajuste a sus necesidades específicas.",
    subtitle6: "Cuando esté listo para mudarse, podemos guiarle en:",
    moving: "Recomendar una empresa de mudanzas de buena reputación para manejar sus pertenencias",
    lawyer: "Elegir un abogado para obtener su visa/permiso de residencia en México",
    bank: "Abrir una cuenta bancaria",
    papers: "Encontrar el abogado adecuado para redactar los documentos necesarios para establecer un fideicomiso para su propiedad",
    car: "Recomendar un especialista para obtener la matrícula de su vehículo, si decide comprar uno",
    lastPara: "Esperamos poder ayudarte a hacer de Mérida tu nuevo hogar o emprendimiento comercial. Recuerda que Mérida es uno de los destinos inmobiliarios más atractivos del país.",
    lastPara2: "El Equipo Bussio",
    goodbye: "Un gran servicio no es el resultado de la casualidad",

    subtitle7: "Conoce al equipo",
    xiomaraTitle: "Empresaria y Agente Inmobiliaria ®",
    xiomaraDescription: "Graduada universitaria y empresaria que se unió a la industria inmobiliaria en 2008. Como agente inmobiliaria, también posee una licencia de Home Staging. Su pasión son las ventas, lo cual se refleja en el éxito y los premios recibidos como una de las 10 principales productoras. La filosofía de Xio es presentar al cliente una variedad que va desde apartamentos con una elegancia única hasta casas amplias y cómodas, y asegurarse de ofrecer la alternativa ideal que se adapte a su estilo de vida para que pueda empezar a disfrutar del futuro que merece.",
    teeBeeTitle: "Director/Realtor ®",
    teeBeeDescription: "Tee Bee es un profesional altamente experimentado y logrado, con una carrera de varias décadas marcada por la excelencia. Posee una maestría en ingeniería mecánica y ha dedicado los últimos 16 años trabajando como la mano derecha de Xio. Tee Bee ha desempeñado un papel fundamental en la inspección y evaluación de propiedades, asegurando que cumplan con los altos estándares requeridos para un rendimiento y eficiencia óptimos. Su meticulosa atención al detalle y su profundo conocimiento de los sistemas mecánicos han contribuido significativamente al éxito de Xio. La experiencia de Tee Bee en ingeniería mecánica, combinada con su experiencia práctica en el campo, lo ha convertido en un activo invaluable, impulsando la innovación y la excelencia en cada proyecto que emprende. En Mérida, Yucatán, el destaca como un asesor, entrenador y líder dinámico y de confianza, teniendo un impacto significativo en el panorama inmobiliario. Sus contribuciones no solo han mejorado las capacidades operativas de DMH, sino que también han reforzado su reputación como líder en la industria inmobiliaria en México.",
    mariaRosarioTitle: "Realtor – Greater Merida",
    mariaRosarioDescription: "¡Hola! Soy Charito, una anfitriona apasionada a quien le encanta explorar nuevos lugares, aprender sobre diferentes culturas y disfrutar de momentos especiales con mi familia. Llevo más de 3 años en este emocionante rol, atendiendo al 90% de clientes internacionales, lo que me ha brindado una perspectiva amplia y la capacidad de satisfacer las necesidades de cada uno de ellos. Disfruto de la diversidad de cada día, conociendo a personas maravillosas y aprendiendo constantemente. Mi objetivo es asegurarme de que quienes se hospedan conmigo tengan una experiencia encantadora y una estadía placentera. ¡Bienvenidos a mi mundo de hospitalidad!",
    nancyPuigTitle: "Realtor – Puerto Rico",
    nancyPuigDescription: "Nancy Puig es una experimentada agente de bienes raíces con más de 20 años de experiencia en Puerto Rico. Reconocida por sus habilidades expertas en negociación y su profundo conocimiento de la industria, Nancy se dedica a satisfacer las necesidades de sus clientes con trabajo arduo e integridad. Su genuina amabilidad y profundo amor por Puerto Rico la convierten en una amiga de confianza para todos los que la conocen. Conocida por su lealtad y honestidad, Nancy conoce el mercado inmobiliario a fondo, asegurando que sus clientes encuentren la propiedad perfecta. Realmente ama lo que hace, haciendo que cada transacción sea fluida y agradable. Nancy también tiene una profunda conexión con México, habiendo vivido en Querétaro durante muchos años. Cuando descubrió Mérida, se enamoró del área, y su experiencia será invaluable en su decisión de invertir en la Ciudad Blanca.",
    jorgeMoscossoTitle: "Realtor – Florida",
    jorgeMoscossoDescription: "Con más de 3 años de experiencia como realtor en Florida, mi misión es clara: ayudar a las familias a hacer realidad el sueño de comprar su hogar. No se trata solo de vender propiedades, sino de guiarte en cada paso, para que juntos logremos que tú y tu familia tengan un lugar al que llamar 'hogar'. Porque tu sueño es mi prioridad. ¡Vamos a hacer esta realidad juntos!",
    gamarTitle: "Creador de Contenido",
    gamarDescription: "Gamar es graduado universitario en ciencias de la computación y un experto en tecnología. Sus roles principales incluyen diseño gráfico, creación de contenido, coordinación de TI y desarrollo web. También es un entusiasta de la moda, amante de las redes sociales y siempre encuentra excusas para comer más chocolate",
    mikeLernerTitle: "Vídeo/fotografía",
    mikeLernerDescription: "Mike es graduado del Instituto de Tecnología de Rochester (R.I.T.) con amplia experiencia en cinematografía. Con sede en California, apoya a DMH y a los equipos de ventas con técnicas de iluminación, producción de videos y filmación con drones. La lista de clientes satisfechos de Mike abarca desde artistas de renombre mundial hasta grandes corporaciones.",
    albertoTitle: "Desarrolladores Web",
    albertoDescription: "AV Solutions es responsable de mantener y gestionar el sitio web de DMH, asegurando su funcionalidad, usabilidad y rendimiento. Sus responsabilidades incluyen tareas como diseñar páginas web, actualizar contenido, monitorear el tráfico del sitio e implementar medidas de seguridad para protegerlo contra amenazas cibernéticas. AV Solutions analiza constantemente las métricas web para mejorar la experiencia del usuario y se mantiene informado sobre las últimas tecnologías y tendencias web, garantizando que el sitio web siga siendo competitivo y se alinee con los objetivos de DMH.",
    joseEduardoTitle: "Corredor de Seguros",
    joseEduardoDescription: "Somos una empresa dedicada al servicio de administración de riesgos, análisis y asesoramiento en siniestros, con mas de 36 años de experiencia. Nuestra garantía principal es aportar invariablemente un ahorro en nuestras propuestas contra las pólizas actuales de nuestros clientes en otras compañías sin disminución de condiciones o coberturas.",
    abogadosTitle: "Firma de abogados",
    abogadosDescription: "Somos una firma de abogados con sede en la ciudad de Mérida, Yucatán y en la isla de Cozumel, Quintana Roo, especializada en Derecho Inmobiliario y Derecho Migratorio. Fuimos creados por la abogada Mariana Rodríguez y la abogada asociada Johana Rodríguez con la idea de ayudar a los inversionistas y amigos a navegar el sistema legal mexicano de manera segura y legal, y por supuesto, disfrutar cada paso del proceso."
  },

  en: {
    subtitle1: "What drives us",
    desc1: "Welcome to DMH Real Estate, where our purpose is to inspire and elevate lives and communities through genuine care and enhancement. We are committed to meeting the needs of our clients, empowering our team, and uplifting the neighborhoods we serve.",
    subtitle2: "Care starts with culture",
    desc2: "Our unwavering belief in continuous improvement guides us every day. As one united team, driven by our purpose, mission, and values, we work together to provide exceptional care and service. This dedication is more than just words—it’s the essence of who we are.",
    subtitle3: "Leadership",
    desc3: "Our Executive Leadership team is comprised of diverse and experienced leaders who are devoted to building a better future for our clients, teammates, and the neighborhoods we serve.",
    subtitle4: "Packing up your lives and starting afresh in a new country is a huge move for many.",
    desc4: "We are proud to introduce ourselves. My name is Zio, and my husband is Tee Bee. We have been multilingual realtors since 2008, currently living in Mérida, Yucatán. We relocated from Houston, Texas, to Mérida in September 2023, and we’re here to assist you in an efficient, low- stress, and cost-effective manner.",
    desc5: "We are established in both Houston, Texas, San Juan Puerto Rico, and Mérida, Yucatán, Mexico. We collaborate with local homeowners, companies, builders, and developers who share our philosophy: creating value from the conception to the completion of high-quality, highly profitable real estate projects for end-users and investors alike.",
    desc6: "If you are planning to live or invest in real estate in the city of Mérida or the state of Yucatán, you have come to the right place. We offer a carefully curated range of houses, apartments, offices, penthouses and land, all chosen to provide absolute legal certainty and the best returns on your investment. Most importantly, we strive to ensure you have a wonderful experience and enjoy the process from start to finish.",

    para1: `We are proud to introduce ourselves. My name is Xio (Zio), and my husband is Tee Bee. We have been multilingual realtors since 2008, currently living in Mérida, Yucatán. We relocated from Houston, Texas, to Mérida in September 2023, and we're here to assist you in an efficient, low-stress, and cost-effective manner.`,
    para2: `We are established in both Houston, Texas, and Mérida, Yucatán, Mexico. We collaborate with local homeowners, companies, builders, and developers who share our philosophy: creating value from the conception to the completion of high-quality, highly profitable real estate projects for end-users and investors alike.`,
    para3: `If you are planning to live or invest in real estate in the city of Mérida or the state of Yucatán, you have come to the right place. We offer a carefully curated range of houses, apartments, offices, penthouses and land, all chosen to provide absolute legal certainty and the best returns on your investment. Most importantly, we strive to ensure you have a wonderful experience and enjoy the process from start to finish.`,
    subtitle5: "Here’s what you can expect from us:",
    airport: "Airport pick-up and drop-off",
    program: "Scheduling and visiting as many properties as needed",
    progreso: "Driving you around the city and to the beach in Progreso",
    price: "Negotiating the best price to fit your specific needs",
    subtitle6: "When you are ready to make the move, we can guide you through:",
    moving: "Recommending a reputable moving company to handle your belongings",
    lawyer: "Choosing an attorney to obtain your visa/residency permit in Mexico",
    bank: "Opening a bank account and Mortgage financing",
    papers: "Finding the right attorney to draw up the paperwork to establish a trust for your property",
    car: "Recommending a specialist to obtain your vehicle's license plate, if you choose to purchase one",
    lastPara: "We look forward to assisting you in making Mérida your new home or business venture. Remember, Mérida is one of the most attractive real estate destinations in the country.",
    lastPara2: "The Bussio Team",
    goodbye: "Great service is not a result of chance",
    
    subtitle7: "Meet the team",
    xiomaraTitle: "Master Broker/Realtor ®",
    xiomaraDescription: "Xiomara (Xio) Bussio is a college graduate and businesswoman joined the Real Estate industry in 2008. World Traveler, Wine and Gourmet Food connoisseur. Animal Lover and part time artist. As a realtor, she also holds a Staging license. Her passion is sales, which is reflected in the success and awards received as one of top 10 producers. Xio’s philosophy is to present the customer with a variety that ranges from apartments with unique elegance to spacious and comfortable houses, and to make sure that she presents the ideal alternative to adapt to your lifestyle so that you may start enjoying the future you deserve!",
    teeBeeTitle: "Director/Realtor ®",
    teeBeeDescription: "Tee Bee is a highly experienced and accomplished professional boasting a several- decade career marked by excellence, holds a master’s degree in mechanical engineering and has dedicated the past 16 years working as Xio’s right-hand, Tee Bee has played a pivotal role in inspecting and evaluating properties, ensuring they meet the high standards required for optimal performance and efficiency. His meticulous attention to detail and deep understanding of mechanical systems have significantly contributed to Xio’s success. Tee Bee’s expertise in mechanical engineering, combined with his hands-on experience in the field, has made him an invaluable asset, driving innovation and excellence in every project he undertakes. In Merida, Yucatan,he emerges as a dynamic and trusted advisor, trainer, and leader, making a significant impact on the real estate landscape. His contributions have not only enhanced the operational capabilities of DMH but also reinforced its reputation as a leader in the real estate industry in Mexico.",
    mariaRosarioTitle: "Realtor – Greater Merida",
    mariaRosarioDescription: "Hello! I'm Charito, a passionate host who loves exploring new places, learning about different cultures, and enjoying special moments with my family. I have been in this exciting role for over 3 years, catering to 90% of international clients, which has given me a broad perspective and the ability to meet each of their needs. I enjoy the diversity of each day, meeting wonderful people, and constantly learning. My goal is to ensure that those who stay with me have a delightful experience and a pleasant stay. Welcome to my world of hospitality!",
    nancyPuigTitle: "Realtor – Puerto Rico",
    nancyPuigDescription: "Nancy Puig is a seasoned Realtor with over 20 years of experience in Puerto Rico. Renowned for her expert negotiation skills and in-depth knowledge of the island, Nancy is dedicated to meeting her clients' needs with hard work and integrity. Her genuine friendliness and deep love for Puerto Rico make her a trusted friend to everyone she meets. Known for her loyalty and honesty, Nancy Real Estate inside and out, ensuring her clients find their perfect property. She truly loves what she does, making every transaction seamless and enjoyable. Nancy also has a deep connection with Mexico, having lived in Querétaro for many years. When she discovered Mérida, she fell in love with the area, and her experience will be invaluable in your decision to invest in the White City.",
    jorgeMoscossoTitle: "Realtor – Florida",
    jorgeMoscossoDescription: "With over 3 years of experience as a realtor in Florida, my mission is clear: to help families make their dream of buying a home come true. It's not just about selling properties; it’s about guiding you every step of the way, so that together we can ensure that you and your family have a place to call 'home' Because your dream is my priority. Let's make this a reality together!",
    gamarTitle: "Content Creator",
    gamarDescription: "Gamar is a college graduate with a degree in Computer Science and a savvy technology guru. His primary roles include graphic design, content creation, IT coordination, and web development. He works-hand-in-hand with Maurice (Web Doctor). Additionally, he is a fashion enthusiast, a social media lover, and always finds excuses to eat more chocolate.",
    mikeLernerTitle: "Video/Photography",
    mikeLernerDescription: "Mike is a graduate of the Rochester Institute of Technology (R.I.T.) with extensive experience in cinematography. Based in California, he supports DMH and the sales teams with lighting techniques, video production, and drone filming. Mike’s list of satisfied clients ranges from world-renowned artists to major corporations.",
    albertoTitle: "Web Developers",
    albertoDescription: "AV Solutions is responsible for maintaining and managing DMH’s website, ensuring its functionality, usability, and performance. Their role involves tasks such as designing web pages, updating content, monitoring site traffic, and implementing security measures to protect against cyber threats. AV Solutions constantly analyzes web analytics to improve user experience and stays informed about the latest web technologies and trends, ensuring the website remains competitive and aligns with DMH’s goals.",
    joseEduardoTitle: "Insurance Broker",
    joseEduardoDescription: "We are a company dedicated to risk management services, analysis, and claims consulting, with over 36 years of experience. Our primary guarantee is to consistently offer savings in our proposals compared to our clients' current policies with other companies, without reducing conditions or coverage.",
    abogadosTitle: "Law Firm",
    abogadosDescription: "We are a Law Firm based in the city of Mérida, Yucatán and the island of Cozumel, Quintana Roo, specialized in Real Estate/Property Law and Immigration Law. We were created by lawyer Mariana Rodriguez and associate lawyer Johana Rodriguez with the idea of helping investors and friends navigate the Mexican Legal System in a safe and legal way and of course, enjoying every step of the process."
  }
}

const AboutUs = () => {

  const { language } = useLanguage(); // Usa el contexto de idioma
  const texts = aboutTexts[language];

  return (
    <>
    <Navbar />
    <section className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] px-6 w-full">
      <div className='flex flex-col w-full bg-gray-200 md:flex-row h-96 bg-opacity-80' style={
        {
          backgroundImage: `url(${Fondo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      }>
      </div>
      <div class="py-16 bg-white">  
        <h2 className="z-40 flex items-center justify-center w-full h-full mb-12 text-4xl font-bold text-center text-black">{texts.title}</h2>
        <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div class="md:5/12 lg:w-5/12">
              <img src="https://tailus.io/sources/blocks/left-image/preview/images/startup.png" alt="image" loading="lazy" width="" height=""></img>
            </div>
            <div class="md:7/12 lg:w-6/12">
              <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">{texts.subtitle}</h2>
              <h3 class="text-xl text-gray-900 font-bold md:text-2xl mt-7">{texts.subtitle1}</h3>
              <p class="mt-6 text-gray-600">{texts.desc1}</p>
              <h3 class="text-xl text-gray-900 font-bold md:text-2xl mt-7">{texts.subtitle2}</h3>
              <p class="mt-6 text-gray-600">{texts.desc2}</p>
              <h3 class="text-xl text-gray-900 font-bold md:text-2xl mt-7">{texts.subtitle3}</h3>
              <p class="mt-6 text-gray-600">{texts.desc3}</p>

              <p class="mt-6 text-gray-600">{texts.para1}</p>
              <p class="mt-4 text-gray-600">{texts.para2}</p>
              <p class="mt-4">{texts.para3}</p>
              <h3 class="text-xl text-gray-900 font-bold md:text-2xl mt-7">{texts.subtitle4}</h3>
              <p class="mt-6 text-gray-600">{texts.desc4}</p>
              <p class="mt-6 text-gray-600">{texts.desc5}</p>
              <p class="mt-6 text-gray-600">{texts.desc6}</p>
            </div>
            </div>
          <div class="max-w-4xl mx-auto px-5 mt-16">
            <div class="text-center">
              <h2 class="font-semibold text-3xl">{texts.subtitle5}</h2>
            </div>
            <div class="grid md:grid-cols-2 gap-10 mt-10">
              <div class="flex gap-4 items-start">
                <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                  <FontAwesomeIcon icon={faHandshake} class="w-5 h-5" />
                </span>
                <div>
                  <p class="mt-1 text-gray-500"> {texts.airport} </p>
                </div>
              </div>
              <div class="flex gap-4 items-start">
                <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                <FontAwesomeIcon icon={faCalendarCheck} class="w-5 h-5" />
                </span>
                <div>
                  <p class="mt-1 text-gray-500"> {texts.program} </p>
                </div>
              </div>
              <div class="flex gap-4 items-start">
                <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                  <FontAwesomeIcon icon={faMapMarkedAlt} class="w-5 h-5" />
                </span>
                  <div>
                    <p class="mt-1 text-gray-500"> {texts.progreso} </p>
                  </div>
              </div>
              <div class="flex gap-4 items-start">
                  <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                    <FontAwesomeIcon icon={faMoneyCheckAlt} class="w-5 h-5" />
                  </span>
                  <div>
                    <p class="mt-1 text-gray-500"> {texts.price} </p>
                  </div>
              </div>
            </div>
          </div>
          <div class="max-w-4xl mx-auto px-5 mt-16">
            <div class="text-center">
              <h2 class="font-semibold text-3xl">{texts.subtitle6}</h2>
            </div>
            <div class="grid md:grid-cols-2 gap-10 mt-10">
              <div class="flex gap-4 items-start">
                <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                  <FontAwesomeIcon icon={faTruckMoving} class="w-5 h-5" />
                </span>
                <div>
                  {/* <h3 class="font-semibold text-xl">Trato especial</h3> */}
                  <p class="mt-1 text-gray-500"> {texts.moving} </p>
                </div>
              </div>
              <div class="flex gap-4 items-start">
                <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                <FontAwesomeIcon icon={faBalanceScale} class="w-5 h-5" />
                </span>
                <div>
                  {/* <h3 class="font-semibold text-xl">Atención desde el primer momento</h3> */}
                  <p class="mt-1 text-gray-500"> {texts.lawyer} </p>
                </div>
              </div>
              <div class="flex gap-4 items-start">
                <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                  <FontAwesomeIcon icon={faCreditCard} class="w-5 h-5" />
                </span>
                  <div>
                    {/* <h3 class="font-semibold text-xl">Conocer la ciudad</h3> */}
                    <p class="mt-1 text-gray-500"> {texts.bank} </p>
                  </div>
              </div>
              <div class="flex gap-4 items-start">
                  <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                    <FontAwesomeIcon icon={faFileSignature} class="w-5 h-5" />
                  </span>
                  <div>
                    {/* <h3 class="font-semibold text-xl">Cotización acorde a presupuesto</h3> */}
                    <p class="mt-1 text-gray-500"> {texts.papers} </p>
                  </div>
              </div>
              <div class="flex gap-4 items-start">
                  <span class="text-blue-600 bg-blue-500/10 p-3 rounded-full">
                    <FontAwesomeIcon icon={faCar} class="w-5 h-5" />
                  </span>
                  <div>
                    {/* <h3 class="font-semibold text-xl">Cotización acorde a presupuesto</h3> */}
                    <p class="mt-1 text-gray-500"> {texts.car} </p>
                  </div>
              </div>
            </div>
          </div>
          <p className='mt-12 text-center'>{texts.lastPara}</p>
          <p className='mt-12 text-center'>{texts.lastPara2}</p>
          <p className='mt-12 font-bold text-center'>{texts.goodbye}</p>
        </div>
      </div>
      <div className="w-full">
      <h2 className="text-4xl font-bold text-center text-gray-800">{texts.subtitle7}</h2>
      <div className="grid grid-cols-1 gap-1 mt-10 text-center sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member1} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block object-cover object-center" alt='Xiomara Bussio' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Xiomara (Xio) Bussio</h4>
              <p className="mt-1 text-blue-600">{texts.xiomaraTitle}</p>
              <p className="mt-4">{texts.xiomaraDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member2} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block" alt='Tee Bee' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Tee Bee</h4>
              <p className="mt-1 text-blue-600">{texts.teeBeeTitle}</p>
              <p className="mt-4">{texts.teeBeeDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member3} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block" alt='Maria Rosario' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Maria Rosario (Charito)</h4>
              <p className="mt-1 text-blue-600">{texts.mariaRosarioTitle}</p>
              <p className="mt-4">{texts.mariaRosarioDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member4} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block" alt='Nancy Puig' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Nancy Puig</h4>
              <p className="mt-1 text-blue-600">{texts.nancyPuigTitle}</p>
              <p className="mt-4">{texts.nancyPuigDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member5} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block object-cover" alt='Jorge Moscosso' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Jorge Moscosso</h4>
              <p className="mt-1 text-blue-600">{texts.jorgeMoscossoTitle}</p>
              <p className="mt-4">{texts.jorgeMoscossoDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member6} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block object-cover object-top" alt='Gamar Milano' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Gamar Milano</h4>
              <p className="mt-1 text-blue-600">{texts.gamarTitle}</p>
              <p className="mt-4">{texts.gamarDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member7} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block" alt='Mike Lerner' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Mike Lerner</h4>
              <p className="mt-1 text-blue-600">{texts.mikeLernerTitle}</p>
              <p className="mt-4">{texts.mikeLernerDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member8} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block object-cover object-right" alt='AV Solutions' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">AV Solutions</h4>
              <p className="mt-1 text-blue-600">{texts.albertoTitle}</p>
              <p className="mt-4">{texts.albertoDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member9} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block" alt='Jose Eduardo Cortes' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">Jose Eduardo Cortes</h4>
              <p className="mt-1 text-blue-600">{texts.joseEduardoTitle}</p>
              <p className="mt-4">{texts.joseEduardoDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition-all m-6 flex flex-col justify-between">
          <div>
            <div className="h-32 bg-blue-400"></div>
            <img src={Member10} className="w-36 h-36 border-4 border-white rounded-full -mt-[72px] shadow-xl inline-block object-cover" alt='Maxlife Yucatán' />
            <div className="p-6">
              <h4 className="font-extrabold text-black">MAXLIFE YUCATÁN</h4>
              <p className="mt-1 text-blue-600">{texts.abogadosTitle}</p>
              <p className="mt-4">{texts.abogadosDescription}</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center p-6 mt-6 space-x-4">
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a type="button" className="inline-flex items-center justify-center bg-gray-100 border-none rounded-full outline-none w-7 h-7 hover:bg-gray-200">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div> */}
        </div>
      </div>
    </div>
    </section>
    <Footer />
    </>
  );
};

export default AboutUs;
