import React from 'react';
import Navbar from './Navbar';
import News from './News';
import Footer from './Footer';

const NewsPage = () => {
  return (
    <>
    <Navbar />
    <News />
    {/* <div className='mx-auto mt-80'></div> */}
    <Footer />
    </>
  );
};

export default NewsPage;
