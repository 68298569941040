import React, { useEffect, useState } from 'react';
import { useLanguage } from './LanguageContext';  // Importa el hook de contexto

const newsTexts = {
  es: {
    header: "Últimas noticias",
    noNews: "No existen noticias por el momento",
    readMore: "Leer más.."
  },
  en: {
    header: "Latest News",
    noNews: "There are no news at the moment",
    readMore: "Read more.."
  }
};

const News = () => {

  const { language } = useLanguage(); // Usa el contexto de idioma
  const texts = newsTexts[language];
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch(`https://discovermexicohomes.com/api/noticias/noticias/${language}`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.news && Array.isArray(data.news)) {
          setNews(data.news);
        } else {
          console.error('Unexpected data structure:', data);
        }
      })
      .catch(error => console.error('Fetch error:', error));
  }, [language]);

  return (
    <>
    {/* container px-4 pb-40 mx-auto md:pb-32 md:px-0 */}
    <section id="noticias" className="container px-4 py-16 mx-auto md:px-0">
      <div class="container mx-auto px-6 sm:px-6">
        <h2 class="text-3xl md:text-4xl font-bold md:mt-32 mt-44 mb-16">{texts.header}</h2>
        {news.length > 0 ? news.map((news, index) => (
        <div class="flex justify-center gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-start lg:gap-x-8">
          
            <div key={index} class="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-3xl">
              <div class="flex items-center">
                  <img src={news.image} alt="Noticias Discover Mexico Homes" class="rounded-t-2xl w-full"></img>
              </div>
              <div class="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                <span class="text-indigo-600 font-medium mb-3 block">{news.date}</span>
                <h4 class="text-xl text-gray-900 font-medium leading-8 mb-5">{news.title}</h4>
                <p class="text-gray-500 leading-6 mb-10">{news.description}</p>
                <a href={news.url} target="_blank" class="cursor-pointer text-lg text-indigo-600 font-semibold">{texts.readMore}</a>
              </div>
            </div>
        </div>
        )) : <h3 className="text-2xl text-center text-gray-600">{texts.noNews}</h3>}
      </div>
    </section>
    </>
  );
};

export default News;
