import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faBuilding, faHome, faNewspaper, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Logo from '../img/Logo-DMH.png';
import { AuthContext } from '../crud/AuthContext';

const NavAside = ({ isOpen, toggleSidebar }) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/administration/login');
  };

  return (
    <>
      <button
        onClick={toggleSidebar}
        className="fixed z-50 justify-end p-2 my-2 ml-8 text-white bg-blue-600 rounded-md top-4"
      >
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>
      <aside
        className={`fixed flex flex-col w-64 h-screen px-4 pt-24 pb-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        <a href="#">
          <img className="w-auto h-10 m-auto sm:h-12 md:m-0" src={Logo} alt="" />
        </a>
        <div className="flex flex-col justify-between flex-1 mt-12">
          <nav>
            <Link className="flex items-center px-4 py-2 text-gray-700 rounded-md hover:bg-gray-100 hover:text-gray-700" to="/administration/residencies">
              <FontAwesomeIcon icon={faBuilding} />
              <span className="mx-4 font-medium">Residencias</span>
            </Link>
            <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md hover:bg-gray-100 hover:text-gray-700" to= "/administration/houses">
              <FontAwesomeIcon icon={faHome} />
              <span className="mx-4 font-medium">Propiedades</span>
            </Link>
            <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md hover:bg-gray-100 hover:text-gray-700" to="/administration/news">
              <FontAwesomeIcon icon={faNewspaper} />
              <span className="mx-4 font-medium">Noticias</span>
            </Link>
            <hr className="my-6 border-gray-200" />
          </nav>
          <button
            onClick={handleLogout}
            className="flex items-center px-4 py-2 text-gray-600 transition-colors duration-300 transform rounded-md hover:bg-gray-100 hover:text-gray-700"
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span className="mx-4 font-medium">Cerrar sesión</span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default NavAside;
