import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom';
import LogoHar from '../img/har_logo.png'
import LogoNational from '../img/National_Association_of_REALTORS_Logo.svg.png'
import LogoAmpi from '../img/ampi_logo.png'

const footerTexts = {
  es: {
    officeTitle: "Nuestra Oficina",
    quickLinks: "Enlaces Rápidos",
    businessHours: "Horario de Atención",
    address: "C. 42, Mérida, Yucatán, México",
    phone: "+012 345 67890",
    email: "info@example.com",
    mondayFriday: "Lunes - Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    closed: "Cerrado",
    home: "Inicio",
    aboutUs: "Nosotros",
    properties: "Propiedades",
    partners: "Partners",
    news: "Noticias",
    contact: "Contacto",
  },
  en: {
    officeTitle: "Our Office",
    quickLinks: "Quick Links",
    businessHours: "Business Hours",
    address: "C. 42, Mérida, Yucatán, Mexico",
    phone: "+012 345 67890",
    email: "info@example.com",
    mondayFriday: "Monday - Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    closed: "Closed",
    home: "Home",
    aboutUs: "About Us",
    properties: "Properties",
    partners: "Partners",
    news: "News",
    contact: "Contact",
  }
};

const Footer = () => {

  const { language } = useLanguage(); // Usa el contexto de idioma
  const texts = footerTexts[language]; // Textos según el idioma actual

  return (
    <>
    <section className="w-full h-full px-16 mx-auto text-white bg-blue-950 footer">
      <div className="w-full py-5 mx-auto mt-5 container-fluid">
        <div className="container w-full py-5">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 md:justify-items-center justify-items-start">
            <div className="mb-5">
              <h4 className="mb-4 text-white">{texts.officeTitle}</h4>
              <p className="mb-4"><FontAwesomeIcon className='pl-2' icon={faMapMarkerAlt}></FontAwesomeIcon> C. 42, Mérida, Yucatán, Mexico</p>
              <a href='tel:18327290607' className="mb-4"><FontAwesomeIcon className='pl-2' icon={faPhoneAlt}></FontAwesomeIcon> 1-832-729-0607 </a><br/>
              <a href='tel:18328791699' className="mb-4"><FontAwesomeIcon className='pl-2'icon={faPhoneAlt}></FontAwesomeIcon> 1-832-879-1699 </a><br/>
              <a href='mailto:Info@discovermexicohomes.com' className="mb-4"><FontAwesomeIcon className='pl-2' icon={faEnvelope}></FontAwesomeIcon> Info@discovermexicohomes.com</a>
              <div className="pt-6 pr-4">
                <a rel="noopener noreferrer" className="p-4 hover:bg-white hover:rounded-md hover:text-gray-600">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a rel="noopener noreferrer" className="p-4 hover:bg-white hover:rounded-md hover:text-gray-600">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a rel="noopener noreferrer" className="p-4 hover:bg-white hover:rounded-md hover:text-gray-600">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a rel="noopener noreferrer" className="p-4 hover:bg-white hover:rounded-md hover:text-gray-600">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </div>
              {/* <div className="flex pt-6 pr-4 mt-5 h-36">
                <img src={LogoHar} className="w-1/3 pr-4 mr-4 h-1/3"></img>
                <img src={LogoNational} className="w-1/3 pr-4 h-1/3"></img>
                <img src={LogoAmpi} className="w-1/3 pr-4 mr-4 h-1/3"></img>
              </div> */}
            </div>
            <div className="mb-5">
              <h4 className="mb-4 text-white">{texts.quickLinks}</h4>
              <Link to='/about-us' className="block mb-2 text-gray-400 hover:text-white">{texts.aboutUs}</Link>
              <Link to='/partners' className="block mb-2 text-gray-400 hover:text-white">{texts.partners}</Link>
              <Link to='/noticias' className="block mb-2 text-gray-400 hover:text-white">{texts.news}</Link>
              <Link to='/contacto' className="block mb-2 text-gray-400 hover:text-white">{texts.contact}</Link>
            </div>
            <div className="mb-5">
              <h4 className="mb-4 text-white">{texts.businessHours}</h4>
              <p className="mb-1">{texts.mondayFriday}</p>
              <h6 className="text-light">09:00 am - 07:00 pm</h6>
              <p className="mb-1">{texts.saturday}</p>
              <h6 className="text-light">09:00 am - 12:00 pm</h6>
              <p className="mb-1">{texts.sunday}</p>
              <h6 className="text-light">{texts.closed}</h6>
            </div>
          </div>
          <div className="flex justify-center w-full pt-6 mt-5">
        <div className='flex flex-wrap items-center justify-center gap-8'>
          <img src={LogoHar} className="h-16 w-18 md:w-20 md:h-20 lg:w-20 lg:h-18"></img>
          <img src={LogoNational} className="h-16 w-18 md:w-28 md:h-20 lg:w-32 lg:h-18"></img>
          <img src={LogoAmpi} className="h-16 w-18 md:w-28 md:h-20 lg:w-32 lg:h-18"></img>
        </div>
      </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Footer;
