import React from 'react';
import { useLanguage } from './LanguageContext'; 
import { Link } from 'react-router-dom';

import Fondo from '../img/barrio-residencial-moderno-techo-verde-balcon-generado-ia.png';
import Casa1 from '../img/luxury-pool-villa-spectacular-contemporary-design-digital-art-real-estate-home-house-property-ge.jpg';
import Casa2 from '../img/3d-rendering-house-model.jpg';

const Hero = () => {

  const textos = {
    es: {
      title: "Encuentra la casa de tus sueños",
      description: "Con Discover Mexico Homes, tu hogar ideal está a solo un clic de distancia",
      contactUs: "Contáctanos",
      bilingualAgency: "Agencia Inmobiliaria Bilingüe",
      stepAway: "A un solo paso de tu casa soñada",
      investmentOpportunity: "Oportunidad de inversión",
      homesAndLand: "Viviendas y Terrenos"
    },
    en: {
      title: "Find the home of your dreams",
      description: "With Discover Mexico Homes, your ideal home is just a click away",
      contactUs: "Contact Us",
      bilingualAgency: "Bilingual Real Estate Agency",
      stepAway: "Just one step away from your dream home",
      investmentOpportunity: "Investment Opportunity",
      homesAndLand: "Houses and Land"
    }
  };
  const { language } = useLanguage();
  const texts = textos[language]; // Selecciona los textos según el idioma actual

  return (
    <>
      <section
        id="inicio"
        className="relative flex flex-col w-full min-h-screen pt-32 md:pt-0 md:flex-row"
        style={{
          backgroundImage: `url(${Fondo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="flex flex-col justify-center h-screen p-6 mt-10 bg-gray-200 pt-80 bg-opacity-80 md:w-1/2 md:pl-10 md:pt-32">
          <div className="flex flex-col items-start px-4 text-black text-start md:px-0">
            <h1 className="p-2 mb-2 text-3xl font-bold mt- md:text-4xl md:mt-0">{texts.title}</h1>
            <p className="p-2 text-lg md:text-2xl">{texts.description}</p>
            <Link to="/contacto" className="px-6 py-3 mt-5 font-bold text-white bg-blue-600 rounded hover:bg-blue-700">{texts.contactUs}</Link>
          </div>
          <div className="flex flex-col justify-start w-full mt-5 md:flex-row lg:flex-col xl:flex-row md:mr-5">
            <div className="flex flex-col items-start w-full p-4 mt-3 mr-5 text-black bg-white shadow-md md:w-1/2 lg:w-full xl:w-1/2">
              <img src={Casa1} alt="Casas Discover Mexico Homes" className="object-cover w-full md:h-2/3" />
              <p className="pt-2 pb-2 font-semibold">{texts.bilingualAgency}</p>
              <p className="pb-6">{texts.stepAway}</p>
            </div>
            <div className="flex flex-col items-start w-full p-4 mt-3 mr-5 text-black bg-white shadow-md md:w-1/2 lg:w-full xl:w-1/2">
              <img src={Casa2} alt="Casas Discover Mexico Homes" className="object-cover w-full md:h-2/3" />
              <p className="pt-2 pb-2 font-semibold">{texts.investmentOpportunity}</p>
              <p className="pb-6">{texts.homesAndLand}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
