import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useLanguage } from './LanguageContext';

const contactTexts = {
  es: {
    title: "Contacto",
    subtitle: "Ponte en contacto con nosotros",
    subtitle2: "Preguntános tus dudas o comentarios",
    subject: "Asunto",
    placeholderSubject: "Duda de residencia",
    name: "Nombre Completo",
    email: "Correo Electrónico",
    message: "Mensaje",
    send: "Enviar"
  },

  en: {
    title: "Contact",
    subtitle: "Get in contact with us",
    subtitle2: "Ask us your questions or comments",
    subject: "Subject",
    placeholderSubject: "Residence doubt",
    name: "Full Name",
    email: "Email",
    message: "Message",
    send: "Send Message"
  }
}

const Contact = () => {

  const { language } = useLanguage()
  const texts = contactTexts[language]

  const [formData, setFormData] = useState({
    asunto: '',
    nombreCompleto: '',
    correoElectronico: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData)
    try {
      const response = await fetch('https://discovermexicohomes.com/api/gmail/enviar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      // Verificar primero el estado de la respuesta.
      if (!response.ok) {
        const errorText = await response.text(); // Intenta leer el cuerpo como texto si no es un JSON
        throw new Error(`HTTP error! status: ${response.status}, Body: ${errorText}`);
      }
      if(response.status === 200){
        window.alert('Correo enviado exitosamente')
      } else{
        const data = await response.json();
        window.alert('Correo enviado exitosamente');
      }
    } catch (error) {
      console.error('Failed to submit the form:', error);
      window.alert('No se pudo realizar el envío del correo. Intente nuevamente')
    }
  };
  
  

  return (
    <>
      <Navbar />
      <section id="contacto" className="px-4 m-auto py-36 md:px-0 justify-items-center">
        <div className="mb-6 text-center">
          <img class="object-cover w-full h-64 rounded-lg lg:h-96" src="https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100" alt=""></img>
            <h2 className="mt-16 text-3xl font-bold md:text-4xl">{texts.title}</h2>
            <p className="mt-4 text-gray-700">{texts.subtitle}</p>
          </div>
          <div class="container px-6 mx-auto">
          <div class="lg:flex lg:items-center lg:-mx-6">
              <div class="mt-8 lg:mx-6 w-full m-auto">
                  <div
                      class="w-full px-8 py-10 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl lg:max-w-xl shadow-gray-300/50">
                      <h1 class="text-lg font-medium text-gray-700">{texts.subtitle2}</h1>

                      <form id="contact-form" class="mt-6" action='' method='POST' onSubmit={handleSubmit}>
                          <div class="flex-1">
                              <label class="block mb-2 text-sm text-gray-600 ">{texts.subject}</label>
                              <input type="text" placeholder={texts.placeholderSubject} name='asunto' class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"  onChange={handleChange} required/>
                          </div>
                          
                          <div class="flex-1 mt-6">
                              <label class="block mb-2 text-sm text-gray-600 ">{texts.name}</label>
                              <input type="text" placeholder="John Doe" name='nombreCompleto' class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" onChange={handleChange} required/>
                          </div>

                          <div class="flex-1 mt-6">
                              <label class="block mb-2 text-sm text-gray-600 ">{texts.email}</label>
                              <input type="email" name='correoElectronico' placeholder="johndoe@example.com" class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" onChange={handleChange} required/>
                          </div>

                          <div class="w-full mt-6">
                              <label class="block mb-2 text-sm text-gray-600">{texts.message}</label>
                              <textarea class="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder={texts.message} name='mensaje' onChange={handleChange} required></textarea>
                          </div>

                          <button class="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50" type='submit' name='enviar-form'>
                              {texts.send}
                          </button>
                      </form>
                  </div>
              </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
