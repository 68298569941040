import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = () => {
    const { authToken } = useContext(AuthContext);

    // Si no hay authToken, redirigir al login
    if (!authToken) {
        console.log("No authToken, redirecting to login");
        return <Navigate to="/administration/login" replace />;
    }

    // Si authToken existe, renderizar los componentes anidados en las rutas protegidas
    console.log("authToken present, rendering outlet");
    return <Outlet />;
};

export default ProtectedRoute;
