import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useLanguage } from './LanguageContext'
import newMerida from '../img/partners/New-Merida-Logo.jpg'
import janeByrd from '../img/partners/jane-byrd.jpeg'

const partnersTexts = {
  es : {
    title: "Asociaciones",
    subtitle: "Trabajamos con los mejores en la industria",
    description: "Con una amplia experiencia y perspicacia empresarial, estamos en una posición única para formar asociaciones sólidas y mutuamente beneficiosas. Estas alianzas nos permiten interactuar y conectarnos con diversas audiencias, al mismo tiempo que ayudamos a nuestros clientes a alcanzar sus objetivos únicos. Nuestro enfoque es anticipar las tendencias futuras, hacer recomendaciones informadas y preparar a nuestros clientes para el éxito.",
    partnerTitle1: "Jane Byrd",
    partnerDescription1: "Descripción del Partner 1.",
    partnerTitle2: "New Merida",
    partnerDescription2: "Descripción del Partner 1."
  },

  en: {
    title: "Partnerships",
    subtitle: "We work with the best in the industry",
    description: "With extensive experience and business acumen, we are uniquely positioned to form solid, mutually beneficial partnerships. These alliances enable us to engage and connect with diverse audiences while helping our clients achieve their unique objectives. Our focus is to anticipate future trends, make informed recommendations, and prepare our customers for success.",
    partnerTitle1: "Jane Byrd",
    partnerDescription1: "Partner description 1",
    partnerTitle2: "New Merida",
    partnerDescription2: "Partner description 2"
  }
}

const Partners = () => {

  const {language} = useLanguage()
  const texts = partnersTexts[language]

  return (
    <>
    <Navbar />
      <section id="partners" className="container px-4 py-16 mx-auto md:px-6">
        <div className="mb-12 text-start">
          <h2 className="text-3xl font-bold md:text-4xl md:mt-38 mt-44">{texts.title}</h2>
          <h3 className="text-lg font-bold md:text-xl mt-4 text-gray-900">{texts.subtitle}</h3>
          <p className="mt-4 text-gray-600 text-start">{texts.description}</p>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
            <img src={janeByrd} alt={texts.partnerTitle1} className="object-contain w-full h-40 rounded" />
            <h3 className="mt-4 text-2xl font-bold">{texts.partnerTitle1}</h3>
            {/* <p className="mt-2 text-gray-600">{texts.partnerDescription1}</p> */}
          </div>
          <div className="p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
            <img src={newMerida} alt={texts.partnerTitle2} className="object-contain w-full h-40 rounded" />
            <h3 className="mt-4 text-2xl font-bold">{texts.partnerTitle2}</h3>
            {/* <p className="mt-2 text-gray-600">{texts.partnerDescription2}</p> */}
          </div>
        </div>
      </section>
    <Footer />
    </>
  );
};

export default Partners;
