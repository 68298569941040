import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
import { useLanguage } from './LanguageContext';  // Importa el hook de contexto


const galleryTexts = {
  es: {
    title: "Propiedades Disponibles",
    subtitle: "Todo lo que estás buscando en un mismo lugar",
    bedrooms: 'Habitaciones',
    bathrooms: 'Baños',
    land: 'Terreno',
    learnMore: "Leer Más",
    noHouses: "No hay casas disponibles"
  },
  en: {
    title: "Available Properties",
    subtitle: "Everything you're looking for in one place",
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    land: 'Land',
    learnMore: "Learn More",
    noHouses: "No houses available"
  }
};

const AboutGallery = () => {
  const { language } = useLanguage(); // Usa el contexto de idioma
  const texts = galleryTexts[language];
  const [houses, setHouses] = useState([]);

  useEffect(() => {
    fetch(`https://discovermexicohomes.com/api/casas/casas/${language}`)
      .then(response => response.json())
      .then(data => {
        if (data.houses && Array.isArray(data.houses)){
          //const uniqueHouses = [];
          ////const residenceIds = new Set();  // Set to track residence IDs

          //data.houses.forEach(house => {
          //  if (!residenceIds.has(house.id_residence)) {
          //    residenceIds.add(house.id_residence);
          //    uniqueHouses.push(house);
          //  }
          //});

          setHouses(data.houses);
        } else{
          console.error('Unexpected data structure:', data);
        }
      });
  }, [language]);


  return (
    <section className="container px-4 py-16 mx-auto md:px-0">
      <h2 className="mb-6 text-3xl font-bold text-center md:text-4xl">{texts.title}</h2>
      <p className="mt-4 mb-6 text-center text-gray-700">{texts.subtitle}</p>
      <div className="flex flex-wrap justify-center">
      {houses.length > 0 ? houses.map((house, index) => (
            <div key={index} className="max-w-sm mx-4 my-6 mb-6 overflow-hidden bg-white rounded shadow-lg hover:shadow-xl">
              <img 
                className="w-full object-cover h-64" 
                src={house.images[0] ? house.images[0] : 'https://via.placeholder.com/400'} 
                alt="Property Image" 
              />
              <div className="px-6 py-4">
                <div className="mb-2">
                  <h2 className="text-xl font-bold text-center text-gray-900 md:text-start">{house.title}</h2>
                  <div className="flex items-center">
                    <div className="px-2 py-1 my-2 mr-2 text-xs font-medium text-white bg-blue-600 rounded-full">
                      {house.name}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img src="https://img.icons8.com/windows/24/null/bedroom.png" alt="Bedrooms Icon" />
                    <p className="ml-2 text-sm font-medium text-gray-700">{house.rooms} {texts.bedrooms}</p>
                  </div>
                  <div className="flex items-center">
                    <img src="https://img.icons8.com/pastel-glyph/24/null/bath--v2.png" alt="Bathrooms Icon" />
                    <p className="ml-2 text-sm font-medium text-gray-700">{house.bathrooms} {texts.bathrooms}</p>
                  </div>
                  <div className="flex items-center">
                    <img src="https://img.icons8.com/ios-glyphs/24/null/expand--v1.png" alt="Size Icon" />
                    <p className="ml-2 text-sm font-medium text-gray-700">{house.land}</p>
                  </div>
                </div>
                <div className="w-full mt-4">
                  <p className="text-2xl font-bold text-center text-blue-800 md:text-start">{house.price}</p>
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-4">
                <div className="flex items-center mb-2">
                  <a href={`${house.urlexterno}`}>
                    <button className="learn-more">
                      <span className="circle" aria-hidden="true">
                        <span className="icon arrow"></span>
                      </span>
                      <span className="button-text">{texts.learnMore}</span>
                    </button>
                  </a>
                </div>
                <div className="flex">
                  <a href="tel:+1234567890" className="flex justify-center object-cover w-full p-3 m-auto mb-2 mr-2 text-gray-700 transition duration-300 ease-in-out bg-gray-300 rounded-full md:justify-end hover:bg-gray-400 hover:text-gray-800">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </a>
                  <a href="https://wa.me/1234567890" className="flex object-cover w-full p-3 m-auto mb-2 text-white transition duration-300 ease-in-out bg-green-500 rounded-full hover:bg-green-600">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </div>
              </div>
            </div>
        )) : <h3 className="text-2xl text-center text-gray-600">{texts.noHouses}</h3>}
      </div>
    </section>
  );
};

export default AboutGallery;
