import React, { useEffect, useState } from 'react';
import NavAside from './NavAside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faEdit, faTrash, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

const News = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [news, setNews] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    isEnglish: false,
    url: '',
    image: null
  });

  useEffect(() => {
    fetch('https://discovermexicohomes.com/api/noticias/noticias/all')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => setNews(data))
        .catch(error => console.error('There was a problem with the fetch operation:', error));
}, []);

const openEditModal = (item) => {
  setSelectedItem(item);

  setFormData({
    title: item.title,
    description: item.description,
    date: item.date.split('/').reverse().join('-'),
    isEnglish: Boolean(item.isEnglish),
    url: item.url,
    image: item.image || null,
  });
};


  const closeEditModal = () => {
    setSelectedItem(null);
    setFormData({
        title: '',
        description: '',
        date: '',
        isEnglish: false,
        url: '',
        image: null
    });
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
    setFormData({
        title: '',
        description: '',
        date: '',
        isEnglish: false,
        url: '',
        image: null
    });
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setFormData({
        title: '',
        description: '',
        date: '',
        isEnglish: false,
        url: '',
        image: null
    });
  };

  const handleDelete = async (id) => {
    const confirmar = window.confirm("¿Deseas eliminar esta noticia?");
    if (confirmar) {
      try {
        const url = `https://discovermexicohomes.com/api/noticias/eliminar/${id}`;
        const respuesta = await fetch(url, {
          method: "DELETE",
        });
  
        if (!respuesta.ok) {
          throw new Error('Failed to delete the news item. Status: ' + respuesta.status);
        }
        setNews(news.filter(newsItem => newsItem.id !== id));
        window.alert("Registro eliminado con éxito")
        fetchNews();
      } catch (error) {
        console.error(error);
        // Optionally, update your UI to show an error message
      }
    }
  };  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const processedMainImage = formData.image && formData.image instanceof Blob ? await convertToBase64(formData.image) : formData.image;
      // const processedImages = formData.images.length > 0 && formData.images[0] instanceof Blob
      // ? await Promise.all(formData.images.map(image => image instanceof Blob ? convertToBase64(image) : image))
      // : formData.images;
      // console.log(formData)
      // console.log(processedMainImage)
      // console.log(processedImages)
      console.log(processedMainImage)
      
      const dataToSubmit = {
        ...formData,
        isEnglish: formData.isEnglish ? 1 : 0,
        image: processedMainImage,
        // images: processedImages
      };

      console.log(dataToSubmit)

      const url = selectedItem
        ? `https://discovermexicohomes.com/api/noticias/editar/${selectedItem.id}`
        : 'https://discovermexicohomes.com/api/noticias/nuevo';
      const method = selectedItem ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        body: JSON.stringify(dataToSubmit),
        headers: {"Content-Type": "application/json"},
      });
      const updatedNews = await response.json();
      
      setNews(prevState => {
        if (!Array.isArray(prevState)) {
          console.error("Invalid state encountered:", prevState);
          return [];
        }
      
        return selectedItem
          ? prevState.map(news => news.id === selectedItem.id ? { ...news, ...updatedNews } : news)
          : [...prevState, updatedNews];
      });
      window.alert("Registro guardado correctamente")
      closeEditModal();
      closeAddModal();
      fetchNews();
    } catch (error) {
      console.error('Failed to submit new:', error);
      window.alert("Error al actualizar")
    }
  };

  const fetchNews = async () => {
    try {
      const response = await fetch('https://discovermexicohomes.com/api/noticias/noticias/all');
      const data = await response.json();
      setNews(data);
    } catch (error) {
      console.error('Failed to fetch properties:', error);
    }
  }
  
  useEffect(() => {
    fetchNews();
  }, []);  
  
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];  // Asegura que solo la parte de Base64 se utilice
        resolve(base64Data);
      };
      reader.onerror = error => reject(error);
    });
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "image") {
      setFormData({
        ...formData,
        image: files[0]
      });
    } else if (name === "images") {
      setFormData({
        ...formData,
        images: files ? Array.from(files) : []
      });
    }
  };

  const removeImage = (indexToRemove) => {
    setFormData({
      ...formData,
      image: formData.image.filter((_, index) => index !== indexToRemove)
    });
  };

  const renderImages = (images) => {
    if (!images) return null;
    return images.map((image, index) => {
      const imageUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
      return (
        <div key={index} className="relative mb-2 mr-2">
          <img
            src={imageUrl}
            alt={`Preview ${index}`}
            className="object-cover rounded-md w-28 h-28"
          />
          <button
            type="button"
            className="absolute top-0 right-0 p-1 text-white bg-red-500 rounded-full"
            onClick={() => removeImage(index)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      );
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };  

  return (
    <>
    <NavAside isOpen={isOpen} toggleSidebar={toggleSidebar} />
    <div className="flex">
      <main className={`p-8 mt-14 transition-all duration-300 ${isOpen ? 'ml-64' : 'ml-0'}`}>
        <h1 className="mb-4 text-2xl font-bold">Noticias</h1>
        <button onClick={openAddModal} className="px-4 py-2 mb-4 text-white bg-blue-600 rounded-md">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Agregar Noticia
        </button>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-4">
        {news && news.news ? (
          news.news.map((item) => (
            <div key={item.id} className="overflow-hidden bg-white rounded-lg shadow-md">
              <img src={item.image} alt={item.title} className="object-cover w-full h-48" />
              <div className="p-4">
                <h2 className="mb-2 text-xl font-bold">{item.title}</h2>
                <p className="mb-4 text-gray-600">{item.description}</p>
                <div className="flex space-x-1">
                  <button onClick={() => openEditModal(item)} className="px-4 py-2 my-2 text-white bg-yellow-500 rounded-md">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleDelete(item.id)} className="px-4 py-2 my-2 text-white bg-red-500 rounded-md">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            </div>
            ))
          ): (
            <h4>No existen noticias actualmente.</h4>
          )}
        </div>
      </main>

      {selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-screen-md w-full p-8 overflow-y-auto max-h-[96vh]">
            <h2 className="mb-4 text-2xl font-bold">Editar Noticia</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="title">
                  * Título
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                  placeholder="Título"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="description">
                  * Descripción
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="description"
                  placeholder="Descripción"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="date">
                  * Fecha
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  required
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  value={formData.date}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="url">
                  * Url
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="url"
                  placeholder="Dirección url"
                  name="url"
                  value={formData.url}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-8">
                <label class="inline-flex items-center cursor-pointer">
                  <span class="text-sm font-bold text-gray-700 mr-8">Contenido en Inglés</span>
                  <input 
                    type="checkbox"
                    className="sr-only peer" 
                    name="isEnglish"
                    checked={formData.isEnglish}
                    onChange={handleCheckboxChange}
                  />
                  <div class="flex relative items-end w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="image">
                  * Imagen
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="image"
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={handleFileChange}
                />
                {formData.image && (
                  <div className="relative mt-4 mb-2">
                    <img
                      src={typeof formData.image === 'string' ? formData.image : URL.createObjectURL(formData.image)}
                      alt="Main"
                      className="object-cover rounded-md w-28 h-28"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                <button onClick={closeEditModal} className="px-4 py-2 mr-2 text-gray-700 bg-gray-300 rounded-md">
                  Cancelar
                </button>
                <button type="submit" className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isAddModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-screen-md p-8 overflow-y-auto bg-white rounded-lg max-h-[96vh]">
            <h2 className="mb-4 text-2xl font-bold">Agregar Noticia</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="title">
                  * Título
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                  placeholder="Título"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="description">
                  * Descripción
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="description"
                  placeholder="Descripción"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="url">
                  * Url
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="url"
                  placeholder="Dirección url"
                  name="url"
                  value={formData.url}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="date">
                  * Fecha
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  required
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  value={formData.date}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-8">
                <label class="inline-flex items-center cursor-pointer">
                  <span class="text-sm font-bold text-gray-700 mr-8">Contenido en Inglés</span>
                  <input 
                    type="checkbox" 
                    value="" className="sr-only peer" 
                    name="isEnglish"
                    checked={formData.isEnglish}
                    onChange={handleCheckboxChange}
                  />
                  <div class="flex relative items-end w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="image">
                  * Imagen 
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="image"
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={handleFileChange}
                  required
                />
                {formData.image && (
                  <div className="relative mt-4 mb-2">
                    <img
                      src={typeof formData.image === 'string' ? formData.image : URL.createObjectURL(formData.image)}
                      alt="Main"
                      className="object-cover rounded-md w-28 h-28"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                <button onClick={closeAddModal} className="px-4 py-2 mr-2 text-gray-700 bg-gray-300 rounded-md">
                  Cancelar
                </button>
                <button type="submit" className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  </>
  );
};

export default News;
