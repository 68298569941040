import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Logo from '../img/Lizard.png';

const Login = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://discovermexicohomes.com/api/login/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Removemos el Authorization aquí ya que estás haciendo login
        },
        body: JSON.stringify({ username: user, password: password }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.token) {
        console.log('Token ANTES TOKEN:', localStorage.getItem('authToken'));
        await login(data.token);  // Espera hasta que el token sea actualizado en el contexto y localStorage
        console.log('Token DESPUÉS TOKEN:', localStorage.getItem('authToken'));
        navigate('/administration/residencies');  // Navega una vez que el token ha sido actualizado
        console.log('Token DESPUÉS NAVIGATE:', localStorage.getItem('authToken'));
      } else {
          // Manejo de errores
          alert(data.error || 'No se recibió token');
      }    
    } catch (error) {
      console.error('Error during fetch:', error);
      alert('Error al iniciar sesión. Intente nuevamente.');
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img className="h-20 w-30" src={Logo} alt="logo" />
        <a href="/" className="flex items-center mb-6 text-2xl font-semibold text-blue-700">
          Discover Mexico Homes
        </a>
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Ingrese con su cuenta
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="user" className="block mb-2 text-sm font-medium text-gray-900">
                  Nombre usuario
                </label>
                <input
                  type="text"
                  name="user"
                  id="user"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="John Doe"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                  Contraseña
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                id='submit'
                className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Ingresar
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;