import React, { useEffect, useState } from 'react';
import NavAside from './NavAside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

const Properties = () => {
  // Inicialización de estados al principio del componente.
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [residentialProperties, setResidentialProperties] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    longDescription: '',
    isEnglish: false,
    mainImage: null,
    images: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Mover aquí asegura que se llama siempre y no condicionalmente.

  // useEffect es llamado sin condiciones, directamente en el cuerpo principal del componente.
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Asegúrate de que este hook no se llama condicionalmente.
        const response = await fetch('https://discovermexicohomes.com/api/residencias/residencias/all', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setResidentialProperties(data);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  // Manejo condicional de la renderización basada en el estado de carga o error.
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading properties: {error}</p>;

  const openEditModal = (item) => {
    setSelectedItem(item);
    setFormData({
      title: item.title,
      description: item.description,
      longDescription: item.longDescription || '',
      isEnglish: Boolean(item.isEnglish),
      mainImage: item.mainImage || null,
      images: item.images || []
    });
  };

  const closeEditModal = () => {
    setSelectedItem(null);
    setFormData({
      title: '',
      description: '',
      longDescription: '',
      isEnglish: false,
      mainImage: null,
      images: []
    });
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
    setFormData({
      title: '',
      description: '',
      longDescription: '',
      isEnglish: '',
      mainImage: null,
      images: []
    });
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setFormData({
      title: '',
      description: '',
      longDescription: '',
      isEnglish: '',
      mainImage: null,
      images: []
    });
  };

  // const fetchProperties = async () => {
  //   try {
  //     const token = localStorage.getItem('authToken');
  //     const response = await fetch('http://localhost:3002/residencias/residencias/all', {
  //       headers: { Authorization: `Bearer ${token}` }
  //     });
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     setResidentialProperties(data);
  //   } catch (error) {
  //     console.error('Failed to fetch properties:', error);
  //   }
  // };
  
  // useEffect(() => {
  //   fetchProperties();
  // }, []);  

  const handleDelete = async (id) => {
    const confirmar = window.confirm("¿Deseas eliminar esta residencia?");
    if (confirmar) {
      try {
        const url = `https://discovermexicohomes.com/api/residencias/eliminar/${id}`;
        const respuesta = await fetch(url, {
          method: "DELETE",
        });
        await respuesta.json();
        // fetchProperties();
        // setResidentialProperties(residentialProperties.filter((residencia) => residencia.id !== id));
      } catch (error) {
        console.error(error);
      }
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const processedMainImage = formData.mainImage && formData.mainImage instanceof Blob ? await convertToBase64(formData.mainImage) : formData.mainImage;
      const processedImages = formData.images.length > 0 && formData.images[0] instanceof Blob
      ? await Promise.all(formData.images.map(image => image instanceof Blob ? convertToBase64(image) : image))
      : formData.images;
      // console.log(formData)
      // console.log(processedMainImage)
      // console.log(processedImages)
      
      const dataToSubmit = {
        ...formData,
        isEnglish: formData.isEnglish ? 1 : 0,
        mainImage: processedMainImage,
        images: processedImages
      };

      console.log(dataToSubmit)

      const url = selectedItem
        ? `https://discovermexicohomes.com/api/residencias/editar/${selectedItem.id}`
        : 'https://discovermexicohomes.com/api/residencias/nuevo';
      const method = selectedItem ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        body: JSON.stringify(dataToSubmit),
        headers: {"Content-Type": "application/json"},
      });
      const updatedProperty = await response.json();
      
      setResidentialProperties(prevState => {
        if (!Array.isArray(prevState)) {
          console.error("Invalid state encountered:", prevState);
          return [];
        }
      
        return selectedItem
          ? prevState.map(property => property.id === selectedItem.id ? { ...property, ...updatedProperty } : property)
          : [...prevState, updatedProperty];
      });
      closeEditModal();
      closeAddModal();
      // fetchProperties(); // Optionally refresh list after update
    } catch (error) {
      console.error('Failed to submit property:', error);
    }
  };
  
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];  // Asegura que solo la parte de Base64 se utilice
        resolve(base64Data);
      };
      reader.onerror = error => reject(error);
    });
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "mainImage") {
      setFormData({
        ...formData,
        mainImage: files[0]
      });
    } else if (name === "images") {
      setFormData({
        ...formData,
        images: files ? Array.from(files) : []
      });
    }
  };

  const removeImage = (indexToRemove) => {
    setFormData({
      ...formData,
      images: formData.images.filter((_, index) => index !== indexToRemove)
    });
  };

  const renderImages = (images) => {
    if (!images) return null;
    return images.filter(image => image !== null).map((image, index) => {
      const imageUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
      return (
        <div key={index} className="relative mb-2 mr-2">
          <img
            src={imageUrl}
            alt={`Preview ${index}`}
            className="object-cover rounded-md w-28 h-28"
          />
        </div>
      );
    });
  };
  

  // const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };  
  

  return (
    <>
    <NavAside isOpen={isOpen} toggleSidebar={toggleSidebar} />
    <div className="flex">
    <main className={`p-8 mt-14 transition-all duration-300 ${isOpen ? 'ml-64' : 'ml-0'}`}>
        <h1 className="mb-4 text-2xl font-bold">Residencias</h1>
        <button onClick={openAddModal} className="px-4 py-2 mb-4 text-white bg-blue-600 rounded-md">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Agregar Residencia
        </button>
        {residentialProperties && residentialProperties.residentialProperties && (
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {residentialProperties.residentialProperties.map((item) => (
              <div key={item.id} className="overflow-hidden bg-white rounded-lg shadow-md">
                <img src={item.mainImage} alt={item.title} className="object-cover w-full h-48" />
                <div className="p-4">
                  <h2 className="mb-2 text-xl font-bold">{item.title}</h2>
                  <p className="mb-4 text-gray-600">{item.description}</p>
                  <div className="flex space-x-1">
                    <button onClick={() => openEditModal(item)} className="px-4 py-2 my-2 text-white bg-yellow-500 rounded-md">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => handleDelete(item.id)} className="px-4 py-2 my-2 text-white bg-red-500 rounded-md">
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>

      {selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg max-w-screen-md w-full p-8 overflow-y-auto max-h-[96vh]">
            <h2 className="mb-4 text-2xl font-bold">Editar Residencia</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="title">
                  * Título
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                  placeholder="Título"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="description">
                  * Descripción
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="description"
                  placeholder="Descripción"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="longDescription">
                  * Descripción Larga
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="longDescription"
                  placeholder="Descripción Larga"
                  name="longDescription"
                  value={formData.longDescription}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-8">
                <label class="inline-flex items-center cursor-pointer">
                  <span class="text-sm font-bold text-gray-700 mr-8">Contenido en Inglés</span>
                  <input 
                    type="checkbox"
                    className="sr-only peer" 
                    name="isEnglish"
                    checked={formData.isEnglish}
                    onChange={handleCheckboxChange}
                  />
                  <div class="flex relative items-end w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="mainImage">
                  * Imagen Principal
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="mainImage"
                  type="file"
                  accept="image/*"
                  name="mainImage"
                  onChange={handleFileChange}
                />
                {formData.mainImage && (
                  <div className="relative mt-4 mb-2">
                    <img
                      src={typeof formData.mainImage === 'string' ? formData.mainImage : URL.createObjectURL(formData.mainImage)}
                      alt="Main"
                      className="object-cover rounded-md w-28 h-28"
                    />
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="images">
                  * Otras Imágenes
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="images"
                  type="file"
                  accept="image/*"
                  multiple
                  name="images"
                  onChange={handleFileChange}
                />
                <div className="flex flex-wrap mt-2">
                  {renderImages(formData.images)}
                </div>
              </div>
              <div className="flex justify-end">
                <button onClick={closeEditModal} className="px-4 py-2 mr-2 text-gray-700 bg-gray-300 rounded-md">
                  Cancelar
                </button>
                <button type="submit" className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isAddModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-screen-md p-8 overflow-y-auto bg-white rounded-lg max-h-[96vh]">
            <h2 className="mb-4 text-2xl font-bold">Agregar Residencia</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="title">
                  * Título
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                  placeholder="Título"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="description">
                  * Descripción
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="description"
                  placeholder="Descripción"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="longDescription">
                  * Descripción Larga
                </label>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="longDescription"
                  placeholder="Descripción Larga"
                  name="longDescription"
                  value={formData.longDescription}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-8">
                <label class="inline-flex items-center cursor-pointer">
                  <span class="text-sm font-bold text-gray-700 mr-8">Contenido en Inglés</span>
                  <input 
                    type="checkbox" 
                    value="" className="sr-only peer" 
                    name="isEnglish"
                    checked={formData.isEnglish}
                    onChange={handleCheckboxChange}
                  />
                  <div class="flex relative items-end w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="mainImage">
                  * Imagen Principal
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="mainImage"
                  type="file"
                  accept="image/*"
                  name="mainImage"
                  onChange={handleFileChange}
                  required
                />
                {formData.mainImage && (
                  <div className="relative mt-4 mb-2">
                    <img
                      src={typeof formData.mainImage === 'string' ? formData.mainImage : URL.createObjectURL(formData.mainImage)}
                      alt="Main"
                      className="object-cover rounded-md w-28 h-28"
                    />
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="images">
                  * Otras Imágenes
                </label>
                <input
                  className="w-full px-3 py-2 text-gray-700 border appearance-none focus:outline-none focus:shadow-outline"
                  id="images"
                  type="file"
                  accept="image/*"
                  multiple
                  name="images"
                  onChange={handleFileChange}
                  required
                />
                <div className="flex flex-wrap mt-2">
                  {renderImages(formData.images)}
                </div>
              </div>
              <div className="flex justify-end">
                <button onClick={closeAddModal} className="px-4 py-2 mr-2 text-gray-700 bg-gray-300 rounded-md">
                  Cancelar
                </button>
                <button type="submit" className="px-4 py-2 text-white bg-blue-600 rounded-md">
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  </>
  );
};

export default Properties;
