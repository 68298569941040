import React, { useState, useEffect } from "react";

const PropertyImageGallery = ({ propertyImages }) => {
  const [active, setActive] = useState(propertyImages[0]); // Inicialmente muestra la primera imagen como activa

  useEffect(() => {
    console.log(propertyImages)
    setActive(propertyImages[0]);
  }, [propertyImages]);

  const handleClick = (image) => {
    setActive(image);
  };

  return (
    <div className="grid w-full gap-2">
      {/* <div> */}
        <img
          className="object-cover object-center w-full h-full p-6 m-auto rounded-lg"
          src={active}
          alt={`Main Property image`}
        />
      {/* </div> */}
      <div className="flex justify-center">
        {propertyImages.map((image, index) => (
          <div key={index} className="mx-4 mb-4">
            <img
              onClick={() => handleClick(image)}
              src={image}
              className={`object-cover object-center h-20 max-w-full rounded-lg cursor-pointer ${active === image ? 'border-4 border-blue-200' : ''}`}
              alt={`Property Image ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PropertyImageGallery;
